import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../shared/context/auth-context";
import "../css/bootstrap.min.css";
import "../css/style.css";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import Footer from "../shared/Footer";
import axios from "axios";
import { Formik } from "formik";
import { Modal } from "antd";
import { Carousel, Button } from "antd";
import {
  FaArrowUp,
  FaEnvelopeOpen,
  FaMapMarkerAlt,
  FaPhoneAlt,
} from "react-icons/fa";
function Contact() {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselRef = useRef(null);
  const nextSlide = () => {
    carouselRef.current.next();
  };
  const prevSlide = () => {
    carouselRef.current.prev();
  };
  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000); // Slide changes every 5 seconds

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div>
      <div class="container-xxl bg-white p-0">
        <div class="container-fluid header bg-white p-0">
          <div class="row g-0 align-items-center flex-column-reverse flex-md-row">
            <div class="col-md-6 p-5 mt-lg-5">
              <h1 class="display-5 animated fadeIn mb-4">Contact Us</h1>
              <nav aria-label="breadcrumb animated fadeIn">
                <ol class="breadcrumb text-uppercase">
                  <li class="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li class="breadcrumb-item text-body" aria-current="page">
                    Contact
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-md-6">
              <div
                style={{
                  width: "100%",
                  margin: "0 auto",
                  position: "relative",
                  marginTop: "120px",
                }}
              >
                <Carousel
                  ref={carouselRef}
                  dotPosition="bottom"
                  autoplay={true}
                  dotStyle={{ bottom: 10 }}
                  afterChange={(current) => setCurrentSlide(current)}
                >
                  <div>
                    <img
                      src="./img/carousel-1.jpg"
                      alt="Image 1"
                      style={{ width: "100%", height: "auto" }}
                    />
                  </div>
                  <div>
                    <img
                      src="./img/carousel-2.jpg"
                      alt="Image 2"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Carousel>
                <Button
                  onClick={prevSlide}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: -30,
                    transform: "translateY(-50%)",
                    background: "#00B98E",
                    border: "1px #00B98E",
                    borderRadius: "50%",
                    padding: "0",
                    width: "60px",
                    height: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LeftOutlined style={{ color: "white", fontSize: "24px" }} />
                </Button>
                <Button
                  onClick={nextSlide}
                  style={{
                    position: "absolute",
                    top: "40%",
                    left: -30,
                    transform: "translateY(-50%)",
                    background: "#00B98E",
                    border: "1px #00B98E",
                    borderRadius: "50%",
                    padding: "0",
                    width: "60px",
                    height: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <RightOutlined style={{ color: "white", fontSize: "24px" }} />
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div class="container-xxl py-5">
          <div class="container">
            <div class="row g-4">
              <div class="col-11">
                <div class="row gy-4">
                  <div
                    class="col-md-6 col-lg-4 wow fadeIn"
                    data-wow-delay="0.1s"
                  >
                    <div class="bg-light rounded p-3">
                      <div
                        class="d-flex align-items-center bg-white rounded p-3"
                        style={{ border: "1px dashed rgba(0, 185, 142, .3)" }}
                      >
                        <div
                          class="icon me-3"
                          style={{ width: "45px", height: "45px" }}
                        >
                          <i class="fa fa-map-marker-alt text-primary">
                            <FaMapMarkerAlt
                              style={{ fontSize: "30px", marginLeft: "5px" }}
                            />
                          </i>
                        </div>
                        <span>
                          281 House,11 Street, ModalTown Phase I, Wah Cantt
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-6 col-lg-4 wow fadeIn"
                    data-wow-delay="0.1s"
                  >
                    <div class="bg-light rounded p-3">
                      <div
                        class="d-flex align-items-center bg-white rounded p-3"
                        style={{ border: "1px dashed rgba(0, 185, 142, .3)" }}
                      >
                        <div
                          class="icon me-3"
                          style={{ width: "45px", height: "45px" }}
                        >
                          <i class="fa fa-map-marker-alt text-primary">
                            <FaEnvelopeOpen
                              style={{ fontSize: "30px", paddingLeft: "7px" }}
                            />
                          </i>
                        </div>
                        <span>info@tarimarketing.com </span>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-md-6 col-lg-4 wow fadeIn"
                    data-wow-delay="0.5s"
                  >
                    <div class="bg-light rounded p-3">
                      <div
                        class="d-flex align-items-center bg-white rounded p-3"
                        style={{ border: "1px dashed rgba(0, 185, 142, .3)" }}
                      >
                        <div
                          class="icon me-3"
                          style={{ width: "45px", height: "45px" }}
                        >
                          <i class="fa fa-phone-alt text-primary">
                            <FaPhoneAlt
                              style={{ fontSize: "24px", paddingLeft: "5px" }}
                            />
                          </i>
                        </div>
                        <span>+012 345 6789</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{width: "50%"}}>
                <iframe
                  width="100%"
                  height="400"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Plot%20No%20280,%20Street%20No%2011,%20Wah%20Model%20Town,%20Wah%20Cantt,%20Wah%20Model%20Town,%20Wah%20Cantt,%20Rawalpindi,%20Punjab%2047040,%20Pakistan+(Tari%20Soft)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                >
                  <a href="https://www.maps.ie/distance-area-calculator.html">
                    measure area map
                  </a>
                </iframe>
              </div>

              <div class="col-md-5" >
                <div class="wow fadeInUp" data-wow-delay="0.5s">
                  <Formik
                    initialValues={{
                      name: "",
                      email: "",
                      subject: "",
                      message: "",
                      userId: "",
                    }}
                    onSubmit={(values, actions) => {
                      Modal.confirm({
                        title: "Are you sure, you want to submit ?",
                        cancelText: "No",
                        okText: "Yes",
                        okType: "primary",
                        onOk: async () => {
                          try {
                            const response = await axios({
                              method: "post",
                              url: `${process.env.REACT_APP_BASE_URL}/contactus/`,
                              data: {
                                name: values.name,
                                email: values.email,
                                subject: values.subject,
                                message: values.message,
                                userId: auth.userId,
                              },
                            });
                            if (response.status === 200) {
                              Modal.success({
                                title: "Contacted Successfully!",
                              });
                              navigate("/");
                            }
                          } catch (err) {
                            const message =
                              err.response.data.message || "error";
                            Error(
                              message ||
                                "Something went wrong,Please try again!"
                            );
                            alert(
                              err.response.data.message,
                              err.response.status
                            );
                          }
                        },
                      });
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div class="row g-3">
                          <div class="col-md-6">
                            <div class="form-floating">
                              <input
                                type="text"
                                class="form-control"
                                required
                                id="name"
                                name="name"
                                placeholder="Your Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                              />
                              <label for="name">Your Name</label>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-floating">
                              <input
                                type="email"
                                class="form-control"
                                required
                                id="email"
                                name="email"
                                placeholder="Your Email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                              />
                              <label for="email">Your Email</label>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-floating">
                              <input
                                type="text"
                                class="form-control"
                                required
                                id="subject"
                                name="subject"
                                placeholder="Subject"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.subject}
                              />
                              <label for="subject">Subject</label>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-floating">
                              <textarea
                                class="form-control"
                                required
                                id="message"
                                name="message"
                                placeholder="Leave a message here"
                                style={{ height: "150px" }}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.message}
                              ></textarea>
                              <label for="message">Message</label>
                            </div>
                          </div>
                          <div class="col-12">
                            <button
                              class="btn btn-primary w-100 py-3"
                              type="submit"
                            >
                              Send Message
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />

        <button
          href="/"
          class="btn btn-lg btn-primary btn-lg-square back-to-top"
        >
          <i class="bi bi-arrow-up"><FaArrowUp /></i>
        </button>
      </div>
    </div>
  );
}

export default Contact;
