import React, { useState, useRef, useEffect } from "react";
import "../css/bootstrap.min.css";
import "../css/style.css";
import { Carousel, Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import "./css/about.css";
import { FaArrowUp } from "react-icons/fa";
import Search from "../shared/Search";
import Footer from "../shared/Footer";

function About() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselRef = useRef(null);

  const nextSlide = () => {
    carouselRef.current.next();
  };

  const prevSlide = () => {
    carouselRef.current.prev();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000); // Slide changes every 5 seconds

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div>
      <div class="container-xxl bg-white p-0">
        {/* <div id="spinner"  class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
                    <div  class="spinner-border text-primary" style={{width: "3rem" , height: "3rem"}} role="status">
                        <span  class="sr-only">Loading...</span>
                    </div>
                </div> */}

        {/* <NavbarMenu /> */}

        <div class="container-fluid header bg-white p-0">
          <div class="row g-0 align-items-center flex-column-reverse flex-md-row">
            <div class="col-md-6 p-5 mt-lg-5">
              <h1 class="display-5 animated fadeIn mb-4">About Us</h1>
              <nav aria-label="breadcrumb animated fadeIn">
                <ol class="breadcrumb text-uppercase">
                  <li class="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li class="breadcrumb-item">
                    <a href="/pages">Pages</a>
                  </li>
                  <li class="breadcrumb-item text-body " aria-current="page">
                    About
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-md-6">
              <div
                style={{
                  width: "100%",
                  margin: "0 auto",
                  position: "relative",
                  marginTop: "120px",
                }}
              >
                <Carousel
                  ref={carouselRef}
                  dotPosition="bottom"
                  autoplay={true}
                  dotStyle={{ bottom: 10 }}
                  afterChange={(current) => setCurrentSlide(current)}
                >
                  <div>
                    <img
                      src="./img/carousel-1.jpg"
                      alt="Image 1"
                      style={{ width: "100%", height: "auto" }}
                    />
                  </div>
                  <div>
                    <img
                      src="./img/carousel-2.jpg"
                      alt="Image 2"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Carousel>
                <Button
                  onClick={prevSlide}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: -30,
                    transform: "translateY(-50%)",
                    background: "#00B98E",
                    border: "1px #00B98E",
                    borderRadius: "50%",
                    padding: "0",
                    width: "60px",
                    height: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LeftOutlined style={{ color: "white", fontSize: "24px" }} />
                </Button>
                <Button
                  onClick={nextSlide}
                  style={{
                    position: "absolute",
                    top: "40%",
                    left: -30,
                    transform: "translateY(-50%)",
                    background: "#00B98E",
                    border: "1px #00B98E",
                    borderRadius: "50%",
                    padding: "0",
                    width: "60px",
                    height: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <RightOutlined style={{ color: "white", fontSize: "24px" }} />
                </Button>
              </div>
            </div>
          </div>
        </div>

        <Search />

        <div class="container-xxl py-5">
          <div class="container">
            <div class="row g-5 align-items-center">
              <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                <div class="about-img position-relative overflow-hidden p-5 pe-0">
                  <img class="img-fluid w-100" src="img/about.jpg" alt="" />
                </div>
              </div>
              <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                <h1 class="mb-4">#1 Place To Find The Perfect Property</h1>
                <p class="mb-4">
                  Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit.
                  Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit,
                  sed stet lorem sit clita duo justo magna dolore erat amet
                </p>
                <p>
                  <i class="fa fa-check text-primary me-3"></i>Tempor erat elitr
                  rebum at clita
                </p>
                <p>
                  <i class="fa fa-check text-primary me-3"></i>Aliqu diam amet
                  diam et eos
                </p>
                <p>
                  <i class="fa fa-check text-primary me-3"></i>Clita duo justo
                  magna dolore erat amet
                </p>
                <a class="btn btn-primary py-3 px-5 mt-3" href="/readmore">
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="container-xxl ">
          <div class="container" style={{ paddingRight: 20, marginLeft: 1 }}>
            <div class="bg-light rounded p-3">
              <div
                class="bg-white rounded p-4"
                style={{ border: "1px dashed rgba(0, 185, 142, .3)" }}
              >
                <div class="row g-5 align-items-center">
                  <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                    <img
                      class="img-fluid rounded w-100"
                      src="img/call-to-action.jpg"
                      alt=""
                    />
                  </div>
                  <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                    <div class="mb-4">
                      <h1 class="mb-3">Contact With Us</h1>
                      <p>
                        Have a question or ready to take the next step? Reach
                        out to us - we're here to assist! Contact our team today
                        for personalized solutions and exceptional service.
                      </p>
                    </div>
                    <a
                      href={`tel:923345284828`}
                      class="btn btn-primary py-3 px-4 me-2"
                    >
                      <i class="fa fa-phone-alt me-2"></i>Make A Call
                    </a>
                    {/* <a href="/getapp" class="btn btn-dark py-3 px-4">
                      <i class="fa fa-calendar-alt me-2"></i>Get Appoinment
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-xxl py-5">
          <div class="container agents">
            <div
              class="text-center mx-auto mb-5 wow fadeInUp agent-heading"
              data-wow-delay="0.1s"
              style={{ maxWidth: "600px" }}
            >
              <h1 class="mb-3">Property Agents</h1>
              <p>
                Our company's sales agent embodies the pinnacle of
                professionalism, dedication, and expertise, consistently
                delivering flawless sales strategies and cultivating lasting
                client relationships
              </p>
            </div>
            <div class="row g-4 agents-row">
              <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div class="team-item rounded overflow-hidden">
                  <div class="position-relative">
                    <img class="img-fluid" src="img/agent1.jpeg"  alt="" />
                    {/* <div class="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                      <a class="btn btn-square mx-1" href="/">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                      <a class="btn btn-square mx-1" href="/">
                        <i class="fab fa-twitter"></i>
                      </a>
                      <a class="btn btn-square mx-1" href="/">
                        <i class="fab fa-instagram"></i>
                      </a>
                    </div> */}
                  </div>
                  <div class="text-center p-4 mt-3">
                    <h5 class="fw-bold mb-0">Farhan</h5>
                    <small>Sales Executive</small>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div class="team-item rounded overflow-hidden">
                  <div class="position-relative">
                    <img class="img-fluid" src="img/agent2.jpeg" alt="" />
                    {/* <div class="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                      <a class="btn btn-square mx-1" href="/">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                      <a class="btn btn-square mx-1" href="/">
                        <i class="fab fa-twitter"></i>
                      </a>
                      <a class="btn btn-square mx-1" href="/">
                        <i class="fab fa-instagram"></i>
                      </a>
                    </div> */}
                  </div>
                  <div class="text-center p-4 mt-3">
                    <h5 class="fw-bold mb-0">Ahsan</h5>
                    <small>Sales Executive</small>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                <div class="team-item rounded overflow-hidden">
                  <div class="position-relative">
                    <img class="img-fluid" src="img/agent3.jpg" alt="" />
                    {/* <div class="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                      <a class="btn btn-square mx-1" href="/">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                      <a class="btn btn-square mx-1" href="/">
                        <i class="fab fa-twitter"></i>
                      </a>
                      <a class="btn btn-square mx-1" href="/">
                        <i class="fab fa-instagram"></i>
                      </a>
                    </div> */}
                  </div>
                  <div class="text-center p-4 mt-3">
                    <h5 class="fw-bold mb-0">Umaid</h5>
                    <small>Sales Executive</small>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                <div class="team-item rounded overflow-hidden">
                  <div class="position-relative">
                    <img class="img-fluid" src="img/agent5.jpeg" alt="" />
                    {/* <div class="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                      <a class="btn btn-square mx-1" href="/">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                      <a class="btn btn-square mx-1" href="/">
                        <i class="fab fa-twitter"></i>
                      </a>
                      <a class="btn btn-square mx-1" href="/">
                        <i class="fab fa-instagram"></i>
                      </a>
                    </div> */}
                  </div>
                  <div class="text-center p-4 mt-3">
                    <h5 class="fw-bold mb-0">Asif</h5>
                    <small>Sales Executive</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />

        <a
          href="/about"
          class="btn btn-lg btn-primary btn-lg-square back-to-top"
        >
          <i class="bi bi-arrow-up"></i>
          <FaArrowUp />
        </a>
      </div>
    </div>
  );
}

export default About;
