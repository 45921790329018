import React, { useState, useEffect } from "react";
import axios from "axios";
import "./css/datalist.css"; // Import the CSS file
import { Modal } from "antd";

const Component = ({ setCity, setArea }) => {
  const [cityData, setCityData] = useState([]);
  const [text, setText] = useState("");
  const [areaText, setAreaText] = useState("");
  const [addedCities, setAddedCities] = useState([]);
  const [selectedCityData, setSelectedCityData] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/property/cities`
        );

        setCityData(data);
      } catch (error) {
        // Handle the error
      }
    };

    fetchData();
  }, []);

  const onChange = (event) => {
    const value = event.target.value;
    setText(value);
    setCity(value);

    const selectedCity = cityData.find((city) => city.city === value);
    setSelectedCityData(selectedCity);
  };

  const onAreaChange = (event) => {
    const value = event.target.value;
    setAreaText(value);
    setArea(value);
  };

  const handleAddCity = async () => {
    if (
      text &&
      !cityData.some((city) => city.city === text) &&
      !addedCities.some((city) => city.city === text)
    ) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/property/addcities`,
          { city: text, areas: [] }
        );
        const newCity = response.data;
        setAddedCities([...addedCities, newCity]);
        setText("");
        setSelectedCityData(newCity);
        if (response.data.message) {
          Modal.warning({
            title: response.data.message,
          });
        } else {
          if (response.status === 200) {
            Modal.success({
              title: "Success",
              content: `City is added! Please select the city from the list.`,
            });
          }
        }
      } catch (error) {
        // Handle the error
      }
    }
  };

  const handleAddArea = async () => {
    if (areaText.trim() && text) {
      try {
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/property/addareas`,
          { city: text, area: { areaText } }
        );
        const updatedCities = addedCities.map((city) => {
          if (city.city === text && !city.areas.includes(areaText)) {
            return {
              ...city,
              areas: [...city.areas, areaText],
            };
          }
          return city;
        });

        setAddedCities(updatedCities);
        setAreaText("");
        setSelectedArea(areaText);
        Modal.success({
          title: "Success",
          content: `City Area added! Please select the city Area from the list.`,
        });
      } catch (error) {
        // Handle the error
      }
    }
  };

  const isCityExists = cityData.some((city) => city.city === text);
  const isCityAdded = addedCities.some((city) => city.city === text);
  const isAreaAdded = addedCities.some(
    (city) => city.city === text && city.areas.includes(areaText)
  );
  const disabled = isCityExists || isCityAdded || !text.trim();
  const areaDisabled = isAreaAdded || !text;
  const areaDisabledButton =
    isAreaAdded ||
    !text ||
    !areaText.trim() ||
    selectedCityData?.cityareas?.some((area) => area.areaText === areaText);

  return (
    <div className="component-container">
      <div className="input-group">
        <div className="form-floating">
          <input
            className="form-control in1"
            type="search"
            list="cityList"
            placeholder="City"
            autoComplete="on"
            value={text}
            onChange={onChange}
            style={{ color: "black" }}
          />
          <datalist id="cityList">
            {cityData.map((city) => (
              <option key={city.city} value={city.city} />
            ))}
          </datalist>
        </div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleAddCity}
          disabled={disabled}
        >
          Add City
        </button>
      </div>
      <div className="input-group">
        <div className="form-floating">
          <input
            className="form-control"
            type="search"
            list="areaList"
            autoComplete="on"
            placeholder="City Area"
            value={areaText}
            onChange={onAreaChange}
            disabled={areaDisabled}
          />
          <datalist id="areaList">
            {selectedCityData?.cityareas &&
              selectedCityData?.cityareas?.map((area) => (
                <option key={area.areaText} value={area.areaText} />
              ))}
          </datalist>
        </div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleAddArea}
          disabled={areaDisabledButton}
        >
          Add Area
        </button>
      </div>
    </div>
  );
};

export default Component;
