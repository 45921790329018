// import React from 'react';

// const NumberToWords = ({ amount }) => {
//   const convertToWords = (num) => {
//     const units = ['Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten',
//       'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
//     const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

//     if (num === 0) return 'Zero';

//     const convertBelow100 = (num) => {
//       if (num < 20) {
//         return units[num];
//       } else {
//         const unitDigit = num % 10;
//         const tensDigit = Math.floor(num / 10);
//         return tens[tensDigit] + (unitDigit > 0 ? ` ${units[unitDigit]}` : '');
//       }
//     };

//     const convert = (num) => {
//       if (num < 100) {
//         return convertBelow100(num);
//       } else if (num < 1000 ) {
//         const hundredDigit = Math.floor(num / 100);
//         const remainingNum = num % 100;
//         return units[hundredDigit] + ' Hundred' + (remainingNum > 0 ? ` and ${convertBelow100(remainingNum)}` : '');
//       } else {
//         const thousandDigit = Math.floor(num / 1000);
//         const remainingNum = num % 1000;
//         return convertBelow100(thousandDigit) + ' Thousand' + (remainingNum > 0 ? ` ${convert(remainingNum)}` : '');
//       }
//     };
    
//     const crore = Math.floor(num / 10000000);
//     const lakh = Math.floor((num) / 100000);
//     const thousand = Math.floor((num) / 1000);
//     const remainingNum = num % 1000;

//     const result = [];
    
//     if (crore > 0) {
//       result.push(`${convert(crore)} Crore`);
//     }
//     if (lakh > 0) {
//       result.push(`${convert(lakh)} Lakh`);
//     }
//     if (thousand > 0) {
//       result.push(`${convert(thousand)} Thousand`);
//     }
//     if (remainingNum > 0) {
//       result.push(convert(remainingNum));
//     }

//     return result.join(', ');
//   };

//   return (
//     <div>
//       <p>{convertToWords(amount)}</p>
//     </div>
//   );
// };

// export default NumberToWords;


import React from 'react';

function AmountConverter({ amount }) {
  const getOnes = (number) => {
    const ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
    return ones[number] || '';
  };

  const getTeens = (number) => {
    const teens = [
      'ten',
      'eleven',
      'twelve',
      'thirteen',
      'fourteen',
      'fifteen',
      'sixteen',
      'seventeen',
      'eighteen',
      'nineteen',
    ];
    return teens[number] || '';
  };

  const getTens = (number) => {
    const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    return tens[number] || '';
  };

  const getHundreds = (num) => {
    if (num > 0 && num < 10) {
      return getOnes(num);
    }
    if (num >= 10 && num < 20) {
      return getTeens(num % 10);
    }
    if (num >= 20 && num < 100) {
      return `${getTens(Math.floor(num / 10))} ${getOnes(num % 10)}`;
    }
    return '';
  };

  const convertToWords = (input) => {
    const rupees = Number.parseInt(input, 10);
    const output = [];

    if (rupees === 0) {
      output.push('zero');
    } else if (rupees === 1) {
      output.push('one');
    } else {
      const crores = Math.floor(rupees / 10000000) ;
      
      if (crores > 0) {
        if (crores === 100) {
          output.push('hundred crore');
        }
         else {
          output.push(`${getHundreds(crores)} crore`);
        }
      }

      const lakhs = Math.floor(rupees / 100000) % 100;
      if (lakhs > 0) {
        output.push(`${getHundreds(lakhs)} lakh`);
      }

      const thousands = Math.floor(rupees / 1000) % 100;
      if (thousands > 0) {
        output.push(`${getHundreds(thousands)} thousand`);
      }

      const hundreds = Math.floor((rupees % 1000) / 100);
      if (hundreds > 0 && hundreds < 10) {
        output.push(`${getOnes(hundreds)} hundred`);
      }

      const tens = rupees % 100;
      if (tens > 0) {
        if (rupees > 100) output.push('and');
        output.push(`${getHundreds(tens)}`);
      }
    }

    return [ ...output, 'only']
      .join(' ')
      .split(/\s/)
      .filter((e) => e)
      .map((e) => e.substr(0, 1).toUpperCase() + e.substr(1))
      .join(' ');
  };

  const convertedAmount = convertToWords(amount);

  return <p>{convertedAmount}</p>;
}

export default AmountConverter;

