import { useEffect, useState, Fragment } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import success from "../shared/images/success.png";
import styles from "./css/EmailVerify.module.css";

const EmailVerify = () => {
  const [validUrl, setValidUrl] = useState(true);
  const param = useParams();
  useEffect(() => {
    const verifyEmailUrl = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/auth/${param.id}/verify/${param.token}`
        );
        setValidUrl(true);
      } catch (error) {
        setValidUrl(false);
      }
    };
    verifyEmailUrl();
  }, []);

  return (
    <div>
      {validUrl ? (
        <div className={styles.container}>
          <img src={success} alt="success_img" className={styles.success_img} />
          <h1>Email verified successfully</h1>
          <h4>Go to navbar for Login!!</h4>
        </div>
      ) : (
        <div style={{ marginTop: "20%", textAlign: "center" }}>
          <h1>404 Not Found</h1>
        </div>
      )}
    </div>
  );
};

export default EmailVerify;
