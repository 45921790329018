import React, { useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { AuthContext } from "../shared/context/auth-context";
import { EditFilled, UserOutlined, ArrowRightOutlined } from "@ant-design/icons";
import axios from "axios";
import { Formik } from "formik";
import { Modal, Avatar } from "antd";
import "./css/profileDemo.css";
const UserList = ["U", "Lucy", "Tom", "Edward"];
const ColorList = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"];
const GapList = [4, 3, 2, 1];
function ProfileDemo(props) {
  const auth = useContext(AuthContext);
  const [userData, setUserData] = useState([]);
  const [user, setUser] = useState(UserList[0]);
  const [color, setColor] = useState(ColorList[0]);
  const [gap, setGap] = useState(GapList[0]);
  const { id } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/auth/user/${props.userId}`
        );
        setUserData(data);
      } catch (error) {
      }
    };

    fetchData();
  }, []);

  return (
      <div>
        <div 
        >
          <Avatar
            style={{
              backgroundColor: color,
              verticalAlign: "middle",
            }}
            size="large"
            gap={gap}
          >
            {userData?.name}
          </Avatar>
         
        </div>

        

        
      </div>
  );
}

export default ProfileDemo;
