import React, { useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { AuthContext } from "../shared/context/auth-context";
import { EditFilled } from "@ant-design/icons";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import axios from "axios";
import { Formik } from "formik";
import { Modal, Avatar, Grid } from "antd";
import "./css/profile.css";
import SelfListing from "../shared/SelfListing";
const ColorList = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"];
const GapList = [4, 3, 2, 1];
function Profile() {
  const { id } = useParams();
  const [userData, setUserData] = useState([]);
  const [updateModal, setUpdateModal] = useState(false);
  const [color, setColor] = useState(ColorList[0]);
  const [gap, setGap] = useState(GapList[0]);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [editedEmail, setEditedEmail] = useState("");
  const [property, setProperty] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/property/1/${id}`
        );
        setProperty(data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/auth/user/${id}`
        );
        console.log(data);
        setUserData(data);
      } catch (error) {}
    };

    fetchData();
  }, []);
  if (userData === {}) {
    return (
      <div
        id="spinner"
        style={{ backdropFilter: "blur(4px)" }}
        class="show position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
      >
        <div
          class="spinner-border text-primary"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  const handleUpdateModalOpen = () => {
    setUpdateModal(true);
  };

  const handleUpdateModalClose = () => {
    setUpdateModal(false);
  };

  const handleEditEmail = () => {
    setIsEditingEmail(true);
    setEditedEmail(userData.email);
  };

  const handleEmailSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/updateemail`,
        {
          userId: id,
          email: editedEmail,
        }
      );
      if (response.data.message) {
        Modal.warning({
          title: response.data.message,
        });
      } else {
        if (response.status === 200) {
          Modal.success({
            title: response.data,
          });
          window.location.reload();
        }
        setIsEditingEmail(false);
      }
    } catch (error) {}
  };

  const handleCancelEmailEdit = () => {
    setIsEditingEmail(false);
    setEditedEmail("");
  };
  return (
    <div className="container">
      <div className="profile">
        <div className="profile-image">
          <FaUser size={140} color="#2e307d" className="user-icon" />
          <br />
          <br />
          <h4>Profile</h4>
        </div>
        <p>Name: {userData.name}</p>
        {!userData.verified && !isEditingEmail && (
          <div style={{ display: "flex" }}>
            <p>Email: {userData.email}</p>
            <button
              className="edit-email-button"
              onClick={handleEditEmail}
              style={{
                padding: "0px",
                marginLeft: "10px",
                marginTop: "-10px",
                backgroundColor: "transparent",
                border: "none",
                color: "rgb(15, 89, 162)",
              }}
            >
              Edit
              <EditFilled />
            </button>
          </div>
        )}
        {userData.verified ? (
          <p>Email: {userData.email}</p>
        ) : (
          isEditingEmail && (
            <div style={{ marginBottom: "20px" }}>
              <input
                type="text"
                name="email"
                value={editedEmail}
                onChange={(e) => setEditedEmail(e.target.value)}
              />
              <button
                className="save-email-button"
                onClick={handleEmailSubmit}
                style={{
                  padding: "0px",
                  marginLeft: "10px",
                  marginTop: "-10px",
                  backgroundColor: "transparent",
                  border: "none",
                  color: "rgb(15, 89, 162)",
                }}
              >
                Save
              </button>
              <button
                className="cancel-email-button"
                onClick={handleCancelEmailEdit}
                style={{
                  padding: "0px",
                  marginLeft: "10px",
                  marginTop: "-10px",
                  backgroundColor: "transparent",
                  border: "none",
                  color: "rgb(15, 89, 162)",
                }}
              >
                Cancel
              </button>
            </div>
          )
        )}
        <p>WhatsApp No: {userData.phoneno}</p>
        <p>Email Status: {userData.verified ? "Verified" : "NotVerified"}</p>

        <button className="change-password" onClick={handleUpdateModalOpen}>
          Change Password
          <EditFilled />
        </button>
      </div>
      <div className="user-properties">
        <SelfListing property={property} />
      </div>

      <div style={{ marginTop: "50px" }}>
        <Modal
          title="Update Password"
          open={updateModal}
          onCancel={handleUpdateModalClose}
          footer={null}
        >
          <Formik
            initialValues={{
              email: "",
              oldpassword: "",
              newpassword: "",
              confirmpassword: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.oldpassword) {
                errors.oldpassword = "Password Required";
              }
              if (!values.newpassword) {
                errors.newpassword = "Password Required";
              } else if (values.oldpassword === values.newpassword) {
                errors.newpassword = "New Password Should be Changed";
              } else if (values.newpassword.length < 8) {
                errors.newpassword =
                  "New Password must be atleast of 8 characters";
              }
              if (!values.confirmpassword) {
                errors.confirmpassword = "Confirm Password Required";
              } else if (values.newpassword !== values.confirmpassword) {
                errors.confirmpassword = "Incorrect Confirm Password";
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                const response = await axios({
                  method: "post",
                  url: `${process.env.REACT_APP_BASE_URL}/auth/updatepassword`,
                  data: {
                    email: userData.email,
                    oldpassword: values.oldpassword,
                    password: values.newpassword,
                  },
                  // withCredentials: true,
                });
                if (response.data.message) {
                  Modal.warning({
                    title: response.data.message,
                  });
                } else {
                  if (response.status === 200) {
                    alert("Password Updated.");
                    setUpdateModal(false);
                  }
                }
              } catch (err) {
                console.log(err);
              }
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form
                onSubmit={handleSubmit}
                id="editmodal"
                className="w-full max-w-sm"
              >
                <div
                  className="md:flex md:items-center mb-6"
                  style={{ paddingTop: "40px" }}
                >
                  <div
                    className="col-12"
                    style={{ paddingTop: "20px", paddingBottom: "20px" }}
                  >
                    <div className="form-floating">
                      <input
                        type="password"
                        name="oldpassword"
                        className="form-control"
                        id="oldpassword"
                        placeholder="Old Password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.oldpassword}
                      />

                      <label htmlFor="password">Old Password</label>
                    </div>
                  </div>
                  <div
                    className="col-12"
                    style={{ paddingTop: "10px", paddingBottom: "20px" }}
                  >
                    <div className="form-floating">
                      <input
                        type="password"
                        name="newpassword"
                        className="form-control"
                        id="newpassword"
                        placeholder="Your New Password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.newpassword}
                      />
                      <p style={{ color: "red" }}>
                        {errors.newpassword &&
                          touched.newpassword &&
                          errors.newpassword}
                      </p>
                      <label htmlFor="password">New Password</label>
                    </div>
                  </div>
                  <div
                    className="col-12"
                    style={{ paddingTop: "10px", paddingBottom: "20px" }}
                  >
                    <div className="form-floating">
                      <input
                        type="password"
                        name="confirmpassword"
                        className="form-control"
                        id="confirmpassword"
                        placeholder="Confirm Password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirmpassword}
                      />
                      <p style={{ color: "red" }}>
                        {errors.confirmpassword &&
                          touched.confirmpassword &&
                          errors.confirmpassword}
                      </p>
                      <label htmlFor="password">Confirm Password</label>
                    </div>
                  </div>

                  <div
                    className="col-12"
                    style={{ paddingTop: "20px", paddingBottom: "30px" }}
                  >
                    <button
                      className="btn btn-primary w-100 py-3"
                      type="submit"
                      // disabled={isSubmitting}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </Modal>
      </div>
    </div>
  );
}

export default Profile;
