import React, { useState, useEffect } from "react";
import { createSearchParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import CardCarousel from "./CardCarousel";
import "../App.css";
const Search = () => {
  const navigate = useNavigate();
  const [propertyData, setPropertyData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCityArea, setSelectedCityArea] = useState("");
  const [searchTerm, setSearchTerm] = useState("");



  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/property/`
        );
        setPropertyData(data);
      } catch (error) {
        // Handle the error
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/property/cities`
        );
        setCityData(data);
      } catch (error) {
        // Handle the error
      }
    };
    fetchData();
  }, []);

  const handleSearchTerm = (e) => {
    const selectedValue = e.target.value;
    
    setSearchTerm(selectedValue);
    applyFilters({
      searchTerm,
      
     
    });
  };

  const handleCityChange = (e) => {
    const selectedValue = e.target.value;
    const selectedCityData = cityData.find(
      (city) => city.city === selectedValue
    );
    setSelectedCity(selectedCityData);
    applyFilters({
      searchTerm,
      city: selectedCityData,
      cityArea: selectedCityArea,

    });
  };

  const handleCityAreaChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedCityArea(selectedValue);
    applyFilters({
      searchTerm,
      city: selectedCity,
      cityArea: selectedValue,

    });
  };

  const applyFilters = ({
    searchTerm,
    city,
    cityArea,
  }) => {
    let filteredProperties = propertyData;

    if (searchTerm) {
      filteredProperties = filteredProperties.filter((propertyItem) =>
        Object.values(propertyItem).some((value) =>
          String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    if (city && city.city) {
      filteredProperties = filteredProperties.filter(
        (propertyItem) => propertyItem.propertycity === city.city
      );
    }

    if (cityArea) {
      filteredProperties = filteredProperties.filter(
        (propertyItem) => propertyItem.propertycityarea === cityArea
      );
    }

    if (!searchTerm && !city && !cityArea) {
      // Set filteredProperties to an empty array when no filters are applied
      filteredProperties = [];
    }
    setFilteredProperties(filteredProperties);
  };
  const SendData = () => {
   
    navigate("/search",{
      state: {
        id: JSON?.stringify(filteredProperties)
      }
     });
  }

  return (
    <div
      className=" bg-primary mb-5 wow fadeIn search-main"
      data-wow-delay="0.1s"
    >
      <div className="search-second">
        <div className="row g-2">
          <div className="col-lg-3 col-md-3 col-sm-5">
            <input
              type="text"
              className="form-control border-0 py-3"
              placeholder="Search Keyword"
              value={searchTerm}
              onChange={handleSearchTerm}
            />
          </div>

          <div className="col-lg-3 col-md-3 col-sm-6">
            <select
              className="form-select border-0 py-3"
              value={selectedCity?.city}
              onChange={handleCityChange}
            >
              <option value="">City</option>
              {cityData.map((city) => (
                <option key={city.city} value={city.city}>
                  {city.city}
                </option>
              ))}
            </select>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6">
            <select
              className="form-select border-0 py-3"
              value={selectedCityArea}
              onChange={handleCityAreaChange}
              disabled={!selectedCity || !selectedCity.cityareas}
            >
              <option value="">City Area</option>
              {selectedCity &&
                selectedCity?.cityareas?.map((area) => (
                  <option key={area.areaText} value={area.areaText}>
                    {area.areaText}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6" >
           <button onClick={() => {SendData()}} className="btn btn-primary  w-100" style={{padding:"16px", backgroundColor:"#00B98E"}}>
            Search
           </button>
          </div>
        </div>
      </div>
     
    </div>
  );
};

export default Search;
