import React, { useEffect, useState } from "react";
import axios from "axios";
import "./css/propertylisting.css";
import { useParams } from "react-router-dom";
import Listing from "../shared/Listing";

function UserProperties() {
  const { id } = useParams();
  const [property, setProperty] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/property/1/${id}`
        );
        setProperty(data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return (
      <div
        id="spinner"
        style={{ backdropFilter: "blur(4px)" }}
        className="show position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
      >
        <div
          className="spinner-border text-primary"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div
    // className="property-list"
    // style={{ marginTop: "100px", marginLeft: "100px", marginRight: "70px" }}
    >
      <Listing property={property} />
    </div>
  );
}

export default UserProperties;
