import React, { useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { Modal, Avatar, Grid } from "antd";
import "./css/profile.css";
import { FaUser } from "react-icons/fa";

import Listing from "../shared/Listing";

const ColorList = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"];
const GapList = [4, 3, 2, 1];

function Profile() {
  const { id } = useParams();
  const [userData, setUserData] = useState({});
  const [color, setColor] = useState(ColorList[0]);
  const [gap, setGap] = useState(GapList[0]);
  const [property, setProperty] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/property/1/${id}`
        );
        setProperty(data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/auth/user/${id}`
        );
        setUserData(data);
      } catch (error) {
      }
    };

    fetchData();
  }, []);

   
  // if (userData === {}) {
  //   return (
  //     <div id="spinner" style={{backdropFilter: "blur(4px)"}} class="show position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
  //               <div class="spinner-border text-primary" style={{ width: "3rem", height: "3rem" }} role="status">
  //                   <span class="sr-only">Loading...</span>
  //               </div>
  //           </div>
  //   );
  // }
  return (
    <div
      className="container"
    >
      <div className="profile">
        <div className="profile-image">
        <FaUser size={140} color="#2e307d" className="user-icon"/>
          <br/>
          <br/>
          <h4>Profile</h4>
        </div>
        <p>Name: {userData.name}</p>

        <p>Email: {userData.email}</p>

        <p>WhatsApp No: {userData.phoneno}</p>
      </div>
      <div className="user-properties" >
        <Listing property={property}/>
      </div>
    </div>
  );
}

export default Profile;
