import React, { useState, useEffect, useCallback, useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthContext } from "./shared/context/auth-context";
import AppNavbar from "./shared/AppNavbar";
import App from "./App";
import Login from "./shared/login";
import Signup from "./shared/signup";

import About from "./component/about";
import Contact from "./component/contact";
import RentAProperty from "./component/RentAProperty";
import BuyAProperty from "./component/BuyAProperty";
import AddProperty from "./component/AddProperty";
import AddPropertyForm from "./component/AddProperty";
import ViewProperty from "./component/ViewProperty";

import UserProperties from "./component/UserProperties";
import EmailVerify from "./component/EmailVerify";
import Profile from "./component/Profile";
import ViewProfile from "./component/ViewProfile";
import CityProperties from "./component/CityProperties";
import SearchResult from "./component/SearchResult";
let logoutTimer;

function AppRouters() {
  const auth = useContext(AuthContext);
  const [token, setToken] = useState(false);
  const [tokenExpirationTime, setTokenExpirationTime] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState(null);
  const [username, setUsername] = useState(null);
  const [isPageReloading, setIsPageReloading] = useState(false);

  const login = useCallback((isLoggedIn, userId, username) => {
    setIsLoggedIn(true);
    setUserId(userId);
    setUsername(username);

    const expirationTime = new Date().getTime() + 86400000; // 1 day (in milliseconds)
    setTokenExpirationTime(expirationTime);
    localStorage.setItem(
      "userData",
      JSON.stringify({
        isLoggedIn: isLoggedIn,
        userId: userId,
        username: username,
        expirationTime: expirationTime,
      })
    );
  }, []);

  const logout = useCallback(() => {
    setIsLoggedIn(false);
    setToken(null);
    setUserId(null);
    setTokenExpirationTime(null);
    localStorage.removeItem("userData");
  }, []);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    if (
      storedData &&
      storedData.userId &&
      storedData.expirationTime > new Date().getTime()
    ) {
      login(storedData.isLoggedIn, storedData.userId, storedData.username);
    }
  }, []);

  useEffect(() => {
    if (token && tokenExpirationTime) {
      const remainingTime = tokenExpirationTime - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = () => {
    setIsPageReloading(true);
  };

  useEffect(() => {
    if (isPageReloading) {
      setIsPageReloading(false);
      window.location.reload();
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: isLoggedIn,
        userId: userId,
        username: username,
        login: login,
        logout: logout,
      }}
    >
      <div style={{ width:"100%", overflow:"hidden"}}>
        <React.Fragment>
          <Router>
            <AppNavbar userId={userId} />
            <Routes>
              <Route
                path="/:id/verify/:token"
                exact
                element={<EmailVerify />}
              />
            </Routes>
            <Routes>
              <Route path="/" exact element={<App />} />
              <Route path="/signup" exact element={<Signup />} />
              <Route path="/about" exact element={<About />} />
              <Route path="/contact" exact element={<Contact />} />
              <Route path="/rentaproperty" exact element={<RentAProperty />} />
              <Route path="/buyaproperty" exact element={<BuyAProperty />} />
              <Route
                path="/addproperty"
                exact
                element={<AddProperty userId={userId} />}
              />
              <Route
                path="/sellpropertyform"
                exact
                element={<AddPropertyForm />}
              />
              <Route path="/profile/:id" element={<Profile />} />
              <Route path="/viewprofile/:id" element={<ViewProfile />} />
              <Route path="/viewproperty/:id" element={<ViewProperty />} />
              <Route
                path="/myproperties/:id"
                exact
                element={<UserProperties />}
              />
              <Route path="/properties/:purpose/:city" element={<CityProperties />} />
              <Route path="/search" element={<SearchResult />} />
            </Routes>
          </Router>
        </React.Fragment>
      </div>
    </AuthContext.Provider>
  );
}

export default AppRouters;
