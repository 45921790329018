import React, { useState, useContext, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Modal, Form, Input, Button } from "antd";
import { Formik } from "formik";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import { AuthContext } from "./context/auth-context";
import axios from "axios";

const LikeButton = ({ id, initiallikedCount, onLike }) => {
  const navigate = useNavigate();
  const [likedCount, setLikedCount] = useState(initiallikedCount);
  const [liked, setLiked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [signup, setSignup] = useState(false);
  const [newPasswordModal, setNewPasswordModal] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [email, setEmail] = useState("");
  const auth = useContext(AuthContext);

  const regex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const phoneNoRegex = /^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/gm;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/property/single/${id}`
        );
        setLikedCount(data[0].likecount);
      } catch (error) {}
    };

    fetchData();
  }, []);
  useEffect(() => {
    setLiked(likedCount > 0);
  }, [likedCount]);

  const handleLikeClick = () => {
    let newLikedCount;
    if (auth.isLoggedIn) {
      let newLikedCount = liked ? likedCount - 1 : likedCount + 1;
      newLikedCount = Math.max(newLikedCount, 0);
      setLiked(!liked);
      onLike(id, newLikedCount);
      setLikedCount(newLikedCount);
      axios.patch(`${process.env.REACT_APP_BASE_URL}/property/like/${id}`, {
        likecount: newLikedCount,
      });
    } else {
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleLoginModalClose = () => {
    setLoginModal(false);
    // navigate('/');
  };
  const handleOTPModalClose = () => {
    setOtpModal(false);
    navigate("/");
  };
  const handleNewPasswordModalClose = () => {
    setNewPasswordModal(false);
    navigate("/");
  };
  const showSignupModal = () => {
    setSignup(true);
  };
  const handleSignupModalClose = () => {
    setSignup(false);
  };

  return (
    <div>
      {auth.isLoggedIn && liked ? (
        <div
          style={{ fontSize: "20px", color: "red", marginTop: "-10px" }}
          onClick={handleLikeClick}
        >
          <HeartFilled />
        </div>
      ) : (
        <div
          style={{ fontSize: "20px", marginTop: "-10px" }}
          onClick={handleLikeClick}
        >
          <HeartOutlined />
        </div>
      )}

      {!auth.isLoggedIn && showModal && (
        <div>
          <div>
            <Modal
              title={
                <h2 style={{ fontSize: "24px", textAlign: "center" }}>Login</h2>
              }
              open={showModal}
              onCancel={handleCloseModal}
              maskClosable={false}
              width={450}
              footer={null}
              bodyStyle={{
                height: "500px",
                marginTop: "50px",
                // overflowY: "scroll",
                marginLeft: "0px",
                paddingLeft: "0px",
              }}
            >
              <Formik
                initialValues={{ email: "", password: "" }}
                validate={(values) => {
                  const errors = {};
                  if (!values.email) {
                    errors.email = "Required";
                  } else if (!regex.test(values.email)) {
                    errors.email = "Email is Not valid";
                  }
                  if (!values.password) {
                    errors.password = "Password Required";
                  } else if (values.password.length < 8) {
                    errors.password = "Password must be of 8 characters";
                  }
                  return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    const response = await axios({
                      method: "post",
                      url: `${process.env.REACT_APP_BASE_URL}/auth/login`,
                      data: {
                        email: values.email,
                        password: values.password,
                      },
                      withCredentials: true,
                    });
                    if (response.data.message) {
                      Modal.warning({ title: response.data.message });
                    } else {
                      auth.login(
                        response.data.isLoggedIn,
                        response.data.user._id,
                        response.data.user.name
                      );
                      if (response.status === 200) {
                        Modal.success({
                          title: "User Login Successfully!",
                        });
                        setShowModal(false);
                      }
                    }
                  } catch (err) {
                    const messsage = err.response.data.message;
                    Error(messsage || "Something went wrong,Please try again!");
                    alert(err.response.data.message, err.response.status);
                  }
                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    id="editmodal"
                    className="w-full max-w-sm"
                  >
                    <div
                      className="md:flex md:items-center mb-6"
                      style={{ paddingTop: "40px" }}
                    >
                      <div class="col-12" style={{ marginBottom: "20px" }}>
                        <div class="form-floating">
                          <input
                            type="email"
                            name="email"
                            // id="phone_no"
                            class="form-control"
                            placeholder="Your Phone No"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                          <p style={{ color: "red" }}>
                            {errors.email && touched.email && errors.email}
                          </p>
                          <label for="phone_no">Your Email</label>
                        </div>
                      </div>
                      <div
                        class="col-12"
                        style={{
                          paddingTop: "20px",
                          paddingBottom: "20px",
                        }}
                      >
                        <div class="form-floating">
                          <input
                            type="password"
                            name="password"
                            class="form-control"
                            id="password"
                            placeholder="Your Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                          />
                          <p style={{ color: "red" }}>
                            {errors.password &&
                              touched.password &&
                              errors.password}
                          </p>
                          <label for="password">Your Password</label>
                        </div>
                      </div>
                      <div
                        class="col-12"
                        style={{ paddingTop: "60px", textAlign: "center" }}
                      >
                        <p style={{ textalign: "center", fontSize: "20px" }}>
                          Don't have an account?
                          <Link
                            style={{ color: "blue" }}
                            onClick={() => {
                              handleLoginModalClose();
                              setSignup(true);
                            }}
                          >
                            {" "}
                            SignUp
                          </Link>
                          <Link
                            style={{ marginLeft: "70px", textAlign: "center" }}
                            onClick={() => {
                              setOtpModal(true);
                              handleLoginModalClose();
                            }}
                          >
                            {" "}
                            Forget Password
                          </Link>
                        </p>
                      </div>
                      <div
                        class="col-12"
                        style={{
                          paddingTop: "20px",
                          paddingBottom: "30px",
                        }}
                      >
                        <button
                          class="btn btn-primary w-100 py-3"
                          type="submit"
                          disabled={isSubmitting}
                          // onClick={handleClick}
                        >
                          Login
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </Modal>
            <Modal
              style={{ fontSize: "24px", textAlign: "center" }}
              open={otpModal}
              onCancel={handleOTPModalClose}
              maskClosable={false}
              width={450}
              footer={null}
              bodyStyle={{
                marginTop: "50px",
                marginLeft: "0px",
                paddingLeft: "0px",
                textAlign: "center",
              }}
            >
              <Formik
                initialValues={{ email: "", otp: "" }}
                validate={(values) => {
                  const errors = {};
                  // Validation logic
                  return errors;
                }}
                onSubmit={async (values, actions) => {
                  setEmail(values.email);
                  try {
                    const response = await axios({
                      method: "post",
                      url: `${process.env.REACT_APP_BASE_URL}/auth/send-otp`,
                      data: {
                        email: values.email,
                      },
                    });
                    // console.log(response);
                    if (response.data.message) {
                      Modal.warning({
                        title: response.data.message,
                      });
                    } else {
                      if (response.status === 200) {
                        Modal.success({
                          title: response.data,
                        });
                        setNewPasswordModal(true);
                        handleOTPModalClose(); // Close the OTP modal
                      }
                    }
                  } catch (err) {
                    alert("Something went wrong. Please try again!");
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    id="editmodal"
                    className="w-full max-w-sm"
                  >
                    <div
                      className="md:flex md:items-center mb-6"
                      style={{ paddingTop: "40px" }}
                    >
                      <div
                        className="col-12"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div className="form-floating" style={{ width: "70%" }}>
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            id="email"
                            placeholder="Your Email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                          <p style={{ color: "red" }}>
                            {errors.email && touched.email && errors.email}
                          </p>
                          <label htmlFor="password">Email</label>
                        </div>
                        <div style={{ width: "30%", margin: "0px" }}>
                          <button
                            className="btn btn-primary w-full py-2"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            Send Email
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </Modal>
            <Modal
              style={{ fontSize: "24px", textAlign: "center" }}
              open={newPasswordModal}
              onCancel={handleNewPasswordModalClose}
              maskClosable={false}
              width={450}
              footer={null}
              bodyStyle={{
                height: "500px",
                marginTop: "50px",
                overflowY: "scroll",
                marginLeft: "0px",
                paddingLeft: "0px",
                textAlign: "center",
              }}
            >
              <Formik
                initialValues={{
                  otp: "",
                  email: "",
                  password: "",
                  confirmpassword: "",
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.password) {
                    errors.password = "Password Required";
                  } else if (values.password.length < 8) {
                    errors.password = "Password must be of 8 characters";
                  }
                  if (!values.confirmpassword) {
                    errors.confirmpassword = "Confirm Password Required";
                  } else if (values.password !== values.confirmpassword) {
                    errors.confirmpassword = "Incorrect Confirm Password";
                  }
                  return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    const response = await axios({
                      method: "post",
                      url: "${process.env.REACT_APP_BASE_URL}/auth/submit-otp",
                      data: {
                        otp: values.otp,
                        email: email,
                        password: values.password,
                      },
                      withCredentials: true,
                    });
                    if (response.data.message) {
                      Modal.warning({
                        title: response.data.message,
                      });
                    } else {
                      if (response.status === 200) {
                        Modal.success({
                          title: response.data,
                        });
                        setLoginModal(true);
                        handleNewPasswordModalClose();
                      }
                    }
                  } catch (err) {
                    console(err);
                    // alert(err.response.data.message, err.response.status);
                  }
                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    id="editmodal"
                    className="w-full max-w-sm"
                  >
                    <div
                      className="md:flex md:items-center mb-6"
                      style={{ paddingTop: "40px" }}
                    >
                      <div
                        className="col-12"
                        style={{ paddingTop: "20px", paddingBottom: "20px" }}
                      >
                        <div className="form-floating">
                          <input
                            type="otp"
                            name="otp"
                            className="form-control"
                            id="otp"
                            placeholder="Your Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.otpModal}
                          />

                          <label htmlFor="password">OTP</label>
                        </div>
                      </div>
                      <div
                        className="col-12"
                        style={{ paddingTop: "10px", paddingBottom: "20px" }}
                      >
                        <div className="form-floating">
                          <input
                            type="password"
                            name="password"
                            className="form-control"
                            id="password"
                            placeholder="Your Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                          />
                          <p style={{ color: "red" }}>
                            {errors.password &&
                              touched.password &&
                              errors.password}
                          </p>
                          <label htmlFor="password">New Password</label>
                        </div>
                      </div>
                      <div
                        className="col-12"
                        style={{ paddingTop: "10px", paddingBottom: "20px" }}
                      >
                        <div className="form-floating">
                          <input
                            type="password"
                            name="confirmpassword"
                            className="form-control"
                            id="confirmpassword"
                            placeholder="Confirm Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmpassword}
                          />
                          <p style={{ color: "red" }}>
                            {errors.confirmpassword &&
                              touched.confirmpassword &&
                              errors.confirmpassword}
                          </p>
                          <label htmlFor="password">Confirm Password</label>
                        </div>
                      </div>

                      <div
                        className="col-12"
                        style={{ paddingTop: "20px", paddingBottom: "30px" }}
                      >
                        <button
                          className="btn btn-primary w-100 py-3"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </Modal>
          </div>
          <div>
            <Modal
              // style={{ marginTop: "20px",  }}
              open={signup}
              onCancel={handleSignupModalClose}
              keyboard={false}
              title={
                <h2 style={{ fontSize: "24px", textAlign: "center" }}>
                  Signup
                </h2>
              }
              footer={null}
              width={450}
              bodyStyle={{
                marginTop: "50px",
              }}
            >
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  phoneno: "",
                  password: "",
                  confirmpassword: "",
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.name) {
                    errors.name = "Required";
                  } else if (values.name.length < 3) {
                    errors.name = "Name should be at least 3 characters";
                  }

                  if (!values.email) {
                    errors.email = "Required";
                  } else if (!regex.test(values.email)) {
                    errors.email = "Email is not valid";
                  }

                  if (!values.phoneno) {
                    errors.phoneno = "Required";
                  } else if (!phoneNoRegex.test(values.phoneno)) {
                    errors.phoneno = "WhatsApp No. is not valid";
                  }

                  if (!values.password) {
                    errors.password = "Required";
                  } else if (values.password.length < 8) {
                    errors.password = "Password must be of 8 characters";
                  }

                  if (!values.confirmpassword) {
                    errors.confirmpassword = "Required";
                  } else if (values.confirmpassword !== values.password) {
                    errors.confirmpassword = "Passwords do not match";
                  }

                  return errors;
                }}
                onSubmit={async (values, actions) => {
                  try {
                    const response = await axios({
                      method: "post",
                      url: `${process.env.REACT_APP_BASE_URL}/auth/signup`,
                      data: {
                        name: values.name,
                        email: values.email,
                        phoneno: values.phoneno,
                        password: values.password,
                      },
                    });
                    if (response.data.message) {
                      Modal.warning({
                        title: response.data.message,
                      });
                    } else {
                      if (response.status === 200) {
                        Modal.success({
                          title: response.data,
                        });
                        handleSignupModalClose();
                      }
                    }
                  } catch (err) {
                    console.log(err);
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    id="editmodal"
                    className="w-full max-w-sm"
                  >
                    <div className="md:flex md:items-center mb-6">
                      <div class="col-12">
                        <div class="form-floating">
                          <input
                            type="text"
                            name="name"
                            class="form-control"
                            id="name"
                            placeholder="Your Name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                          />
                          <p style={{ color: "red" }}>
                            {errors.name && touched.name && errors.name}
                          </p>
                          {/* {errors.name && touched.name && errors.name} */}
                          <label for="name">Name</label>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-floating">
                          <input
                            type="email"
                            class="form-control"
                            id="email"
                            name="email"
                            placeholder="Your Email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                          <p style={{ color: "red" }}>
                            {errors.email && touched.email && errors.email}
                          </p>
                          <label for="phone">Email</label>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-floating">
                          <input
                            type="text"
                            class="form-control"
                            id="phoneno"
                            name="phoneno"
                            placeholder="WhatsApp No."
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phoneno}
                          />
                          <p style={{ color: "red" }}>
                            {errors.phoneno &&
                              touched.phoneno &&
                              errors.phoneno}
                          </p>
                          <label for="password">WhatsApp</label>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-floating">
                          <input
                            type="password"
                            class="form-control"
                            id="password"
                            name="password"
                            placeholder="Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                          />
                          <p style={{ color: "red" }}>
                            {errors.password &&
                              touched.password &&
                              errors.password}
                          </p>
                          <label for="password">Password</label>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="form-floating">
                          <input
                            type="password"
                            class="form-control"
                            id="confirmpassword"
                            placeholder="Confirm Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmpassword}
                          />
                          <p style={{ color: "red" }}>
                            {errors.confirmpassword &&
                              touched.confirmpassword &&
                              errors.confirmpassword}
                          </p>
                          <label for="confirmpassword">Confirm Password</label>
                        </div>
                      </div>

                      <div class="col-12">
                        <p style={{ textalign: "center", fontSize: "20px" }}>
                          {" "}
                          Have an account?{" "}
                          <Link
                            onClick={() => {
                              handleSignupModalClose();
                              setLoginModal(true);
                            }}
                          >
                            {" "}
                            LogIn
                          </Link>
                        </p>
                      </div>
                      <div class="col-12" style={{ paddingBottom: "30px" }}>
                        <button
                          class="btn btn-primary w-100 py-3"
                          type="submit"
                        >
                          SignUp
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </Modal>
          </div>
        </div>
      )}
    </div>
  );
};

export default LikeButton;
