import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import {
  FaArrowUp,
  FaBed,
  FaBath,
  FaCar,
  FaChartArea,
  FaLandmark,
  FaSquare,
  FaWhatsapp,
} from "react-icons/fa";
import images1 from "../shared/images/imgbackground.jpg";
import Carousel from "react-bootstrap/Carousel";
import { Formik } from "formik";
import axios from "axios";
import { Button, Modal } from "antd";
import ImgCarousel from "../shared/ImageCarousel";
import { FaUser } from "react-icons/fa";

import Footer from "../shared/Footer";
import "./css/ViewProperty.css";
import LikeButton from "../shared/Liked";

function ViewProperty() {
  const [property, setProperty] = useState({});
  const [images, setImages] = useState([]);
  const [propertyPrimaryDetaile, setPropertyPrimaryDetaile] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/property/permalink/${id}`
      );
      setImages(data[0].images);
      setProperty(data.at(0));
      setPropertyPrimaryDetaile(
        data[0]?.propertyPrimaryDetaile?.map((item) => JSON.parse(item))
      );
    };

    fetchData();
  }, []);

  if (property.length === 0) {
    return (
      <div
        id="spinner"
        style={{ backdropFilter: "blur(4px)" }}
        class="show position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
      >
        <div
          class="spinner-border text-primary"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const renderImages = () => {
    if (images.length === 0) {
      return (
        <div className="static-image">
          <img
            style={{ width: "100%" }}
            id="login_img"
            src={process.env.PUBLIC_URL + "/img/imgbackground.jpg"}
            alt="Property Image"
          />
        </div>
      );
    }

    if (images.length > 0) {
      const firstImage = images[0];
      const remainingImages = images.slice(1);
      const remainingCount = images.length - 3;

      return (
        <div className="image-gallery" onClick={handleImageClick}>
          <div className="first-image">
            <img
              src={`${process.env.REACT_APP_BASE_URL}/${firstImage}`}
              alt={firstImage.alt}
            />
          </div>
          <div className="remaining-images">
            {remainingImages.slice(0, 2).map((image, index) => (
              <div key={index} className="additional-image">
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/${image}`}
                  alt={image.alt}
                />
              </div>
            ))}
            {remainingCount > 0 && (
              <div className="additional-count">+{remainingCount}</div>
            )}
          </div>
        </div>
      );
    }

    return null;
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      <div>
        <div className="parent-div">
          <div className="card1container" style={{backgroundColor:"gray"}}>
            <div class="col-lg-12 col-md-6">
              <div class="property-item">
                  {/* {renderImages()} */}
                  <Carousel className="carousel1">
                        {images.map((image) => {
                          return (
                            <Carousel.Item>
                              <img
                                className="d-block w-100"
                                src={`${process.env.REACT_APP_BASE_URL}/${image}`}
                                alt="items images"
                              />
                            </Carousel.Item>
                          );
                        })}
                      </Carousel>

                  <Modal
                    // title="Property Details"
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    width={750}
                    bodyStyle={{
                      height: "auto",

                      marginLeft: "0px",
                      padding: "10px",
                    }}
                    okText={"Add"}
                    cancelText={"Back"}
                    footer={null}
                  >
                    <div className="slider">
                      <Carousel>
                        {images.map((image) => {
                          return (
                            <Carousel.Item>
                              <img
                                className="d-block w-100"
                                src={`${process.env.REACT_APP_BASE_URL}/${image}`}
                                alt="items images"
                              />
                            </Carousel.Item>
                          );
                        })}
                      </Carousel>
                    </div>
                  </Modal>

                  <div className="propDetails">
                    <div class="propertypurpose">
                      <h1>
                        {" "}
                        {property?.propertytitle} in{" "}
                        {property?.propertylocation}
                      </h1>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div className="card-container">
            <div className="div-btn">
              <FaUser size={140} color="#2e307d" className="user-icon" />
              <br />
              <div className="button-call">
                <p>{property?.name}</p>

                <Link
                  to={
                    `https://wa.me/${property?.phonenumber}`
                    // `https://wa.me/923310100011`
                  }
                  target="_blank"
                >
                  <button id="buttonforcall" className="btn btn-primary">
                    Whatsapp
                    {/* <FaWhatsapp size={25} /> */}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="cardcontainer">
          <div class="col-lg-12 col-md-6">
            <div class="property-item">
              <div class="position-relative overflow-hidden">
                <div className="propertysize">
                  <p>
                    <FaLandmark className="feature-icon" color="#2E307D" />
                    {property?.propertysize}
                    {property?.propertysizetype}
                  </p>
                  <p style={{ paddingLeft: "20px" }}>
                    <FaSquare className="feature-icon" color="#2E307D" />
                    {property?.propertysubtype}
                  </p>
                </div>
                <div class="feature">
                  <h5>Features</h5>
                  <ul className="property-features">
                    <li>
                      <FaBed className="feature-icon" color="#2E307D" />{" "}
                      {property?.propertynoofbedroom} Bedrooms
                    </li>
                    <li>
                      <FaBath className="feature-icon" color="#2E307D" />{" "}
                      {property?.propertynoofbathroom} Bathrooms
                    </li>
                    <li>
                      <FaCar className="feature-icon" color="#2E307D" />{" "}
                      {property?.propertynoofcarparking} Car Parking
                    </li>
                  </ul>
                </div>
                <div class="propertyprice">
                  <h5 class="propertypriceheading">
                    Rs. {property?.propertyprice}
                  </h5>
                </div>
                <hr />
                <div class="propertydescription">
                  <h3>Description</h3>
                  {property?.propertydescription}
                </div>
                <hr />
                <div>
                  <h3>Details</h3>
                  <div>
                    {propertyPrimaryDetaile?.length > 0 &&
                      propertyPrimaryDetaile?.map((detail) => (
                        <Chip
                          label={`${detail.detailTypeCount} ${detail.detailType} `}
                          style={{
                            backgroundColor: "#2E307D",
                            color: "#ffffff",
                            padding: "20px",
                            marginRight: "10px",
                            marginBottom: "10px",
                            borderRadius: "10px",
                          }}
                        />
                      ))}
                    {property?.propertySecondaryDetaile?.length > 0 &&
                      property?.propertySecondaryDetaile?.map((detail) => (
                        <Chip
                          label={detail}
                          style={{
                            backgroundColor: "#2E307D",
                            color: "#ffffff",
                            padding: "20px",
                            marginRight: "10px",
                            marginBottom: "10px",
                            borderRadius: "10px",
                          }}
                        />
                      ))}
                    {property?.propertyServices?.length > 0 &&
                      property?.propertyServices?.map((service) => (
                        <Chip
                          label={service}
                          style={{
                            backgroundColor: "#2E307D",
                            color: "#ffffff",
                            padding: "20px",
                            marginRight: "10px",
                            marginBottom: "10px",
                            borderRadius: "10px",
                          }}
                        />
                      ))}
                    {property?.propertyEntertainment?.length > 0 &&
                      property?.propertyEntertainment?.map(
                        (entertainmentService) => (
                          <Chip
                            label={entertainmentService}
                            style={{
                              backgroundColor: "#2E307D",
                              color: "#ffffff",
                              padding: "20px",
                              marginRight: "10px",
                              marginBottom: "10px",
                              borderRadius: "10px",
                            }}
                          />
                        )
                      )}
                    {property?.propertyNearByLandmarks?.length > 0 &&
                      property?.propertyNearByLandmarks?.map(
                        (nearByLandmarks) => (
                          <Chip
                            label={nearByLandmarks}
                            style={{
                              backgroundColor: "#2E307D",
                              color: "#ffffff",
                              padding: "20px",
                              marginRight: "10px",
                              marginBottom: "10px",
                              borderRadius: "10px",
                            }}
                          />
                        )
                      )}
                  </div>
                </div>
                <div className="second-div"></div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <a
          href={`/viewproperty/${id}`}
          class="btn btn-lg btn-primary btn-lg-square back-to-top"
        >
          <i class="bi bi-arrow-up">
            <FaArrowUp />
          </i>
        </a>
      </div>
    </div>
  );
}

export default ViewProperty;
