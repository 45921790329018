import React, {useState, useEffect} from "react";
import {
  FaEnvelope,
  FaPhoneAlt,
  FaMapMarkerAlt,
  FaTwitter,
  FaFacebook,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa";
import axios from "axios";
import "./css/footer.css"
function Footer() {
  const [cityData, setCityData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/property/cities`);
        setCityData(data);
      } catch (error) {
      }
    };

    fetchData();
  }, []);

  // if (cityData.length === 0) {
  //   return (
  //     <div id="spinner" style={{backdropFilter: "blur(4px)"}} class="show position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
  //               <div class="spinner-border text-primary" style={{ width: "3rem", height: "3rem" }} role="status">
  //                   <span class="sr-only">Loading...</span>
  //               </div>
  //           </div>
  //   );
  // }

  return (
    <div
      class=" bg-dark text-white-50 footer pt-5 mt-5 wow fadeIn"
      data-wow-delay="0.1s"
      style={{ backgroundColor: "#0E2E50" }}
    >
      <div class="container " >
        <div class="row g-5 w-100 " >
          <div class="col-lg-6 col-md-6">
            <h5 class="text-white mb-4">Get In Touch</h5>
            <p class="mb-2">
              <FaMapMarkerAlt className="location-icon" />
               ModalTown Phase I, Wah Cantt
            </p>
            <p class="mb-2">
              <FaPhoneAlt className="location-icon" />
              +92 330 1111100
            </p>
            <p class="mb-2">
              <FaEnvelope className="location-icon" />
              no_reply@merapakghar.com
            </p>
            <div class="d-flex pt-2">
              <a class="btn btn-outline-light btn-social" href="/">
                <i class="fab fa-twitter">
                  <FaTwitter />
                </i>
              </a>
              <a class="btn btn-outline-light btn-social" href="/">
                <i class="fab fa-facebook-f">
                  <FaFacebook />
                </i>
              </a>
              <a class="btn btn-outline-light btn-social" href="/">
                <i class="fab fa-youtube">
                  <FaYoutube />
                </i>
              </a>
              <a class="btn btn-outline-light btn-social" href="/">
                <i class="fab fa-linkedin-in">
                  <FaLinkedin />
                </i>
              </a>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <h5 class="text-white mb-4">Quick Links</h5>
            <ul>
              <li>
                <a class="btn text-white-50" href="/about">
                  About Us
                </a>
              </li>
              <li>
                <a class="btn text-white-50" href="/contact">
                  Contact Us
                </a>
              </li>
              <li>
                <a class="btn text-white-50" href="/buyaproperty">
                  Buy A Property
                </a>
              </li>
              <li>
                <a class="btn text-white-50" href="/addproperty">
                  Sell A Property
                </a>
              </li>
              <li>
                <a class="btn text-white-50" href="/rentaproperty">
                  Rent A Property
                </a>
              </li>
            </ul>
          </div>
         
          {/* <div class="col-lg-4 col-md-6 ">
            <h5 class="text-white mb-4">Properties</h5>
            <ul style={{display: 'flex', flexDirection:'row', flexWrap:'wrap', listStyle:'none', marginLeft:-40}}>
              {
                cityData.map((data)=>(
              <li>
                <a class="btn text-white-50" href={`/properties/${data.city}`}>
                  {data.city}
                </a>
              </li>
                )
                )
              }
              
              
            </ul>
          </div> */}

        </div>
      </div>
      <div class="container" > 
        <div class="copyright" >
          <div style={{display:"flex", justifyContent:'space-evenly'}}>
            <div class="col-lg-6 col-md-6 text-center text-md-start mb-3 mb-md-0">
              &copy;{" "}
              <a class="border-bottom" href="/">
                Tarisoft
              </a>
              , All Right Reserved.
              <a class="border-bottom" href="https://htmlcodex.com">
                tarisoft
              </a>
            </div>
            <div class="col-lg-6 col-md-6 text-center text-md-end"  >
              <div class="footer-menu">
                <a href="/">Home</a>
                <a href="/about">About</a>
                <a href="/contact">Contact</a>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
