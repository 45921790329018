import React, { useState, useRef, lazy, Suspense } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Listing from "../shared/Listing";
import Search from "../shared/Search";
import "../css/bootstrap.min.css";
import "../css/style.css";
import "./css/propertylisting.css";
import { FaArrowUp } from "react-icons/fa";
import ImgCarousel from "../shared/ImageCarousel";
const Footer = lazy(() => import("../shared/Footer"));
function CityProperties() {
  const { purpose, city } = useParams();
  const [property, setProperty] = useState([]);
  const [allpropertytypes, setAllPropertyType] = useState([]);
  const [propertyData, setPropertyData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [selectedPropertyType, setSelectedPropertyType] = useState("");
  const [selectedPropertyTypeData, setSelectedPropertyTypeData] = useState();
  const [selectedPropertySubType, setSelectedPropertySubType] = useState("");
  const [selectedPropertyPurpose, setSelectedPropertyPurpose] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCityArea, setSelectedCityArea] = useState("");
  const [selectedCityData, setSelectedCityData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [minSize, setMinSize] = useState();
  const [maxSize, setMaxSize] = useState();
  const [sizeType, setSizeType] = useState("");
  const [validationError, setValidationError] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const carouselRef = useRef(null);
  const nextSlide = () => {
    if (carouselRef) {
      carouselRef.current?.next();
    } else {
      return;
    }
  };
  const prevSlide = () => {
    if (carouselRef) {
      carouselRef.current?.prev();
    } else {
      return;
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000); // Slide changes every 5 seconds
    return () => {
      clearInterval(interval);
    };
  }, []);

 

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/property/2/${purpose}/${city}`
      );
      setProperty(data);
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/property/allpropertytypes`
        );
        setAllPropertyType(data);
      } catch (error) { }
    };
    fetchData();
  }, []);


  const applyFilters = ({
    // searchTerm,
    propertyType,
    propertySubType,
    // propertyPurpose,
    // city,
    // cityArea,
    minPrice,
    maxPrice,
    minSize,
    maxSize,
    sizeType,
  }) => {
    let filteredProperties = property;

    // setTimeout(()=>{
    //   console.log('timeout')
    //   Modal.success({
    //     title:'Filtered Applied'
    //   })
    // },
    // 5000)

    if (propertyType) {
      filteredProperties = filteredProperties.filter(
        (propertyItem) => propertyItem.propertytype === propertyType
      );
    }
    if (propertySubType) {
      filteredProperties = filteredProperties.filter(
        (propertyItem) => propertyItem.propertysubtype === propertySubType
      );
    }

    // if (propertyPurpose) {
    //   filteredProperties = filteredProperties.filter(
    //     (propertyItem) => propertyItem.propertypurpose === propertyPurpose
    //   );
    // }

    // if (city && city.city) {
    //   filteredProperties = filteredProperties.filter(
    //     (propertyItem) => propertyItem.propertycity === city.city
    //   );
    // }

    // if (cityArea) {
    //   filteredProperties = filteredProperties.filter(
    //     (propertyItem) => propertyItem.propertycityarea === cityArea
    //   );
    // }

    if (minPrice && maxPrice) {
      filteredProperties = filteredProperties.filter(
        (propertyItem) => propertyItem.propertyprice >= minPrice
      );
      
      filteredProperties = filteredProperties.filter(
        (propertyItem) => propertyItem.propertyprice <= maxPrice
      );
    } else {
      setValidationError(false);
    }



    if (minSize && maxSize && sizeType) {
      filteredProperties = filteredProperties.filter(
        (propertyItem) => propertyItem.propertysize >= minSize
      );

      filteredProperties = filteredProperties.filter(
        (propertyItem) => propertyItem.propertysize <= Number(maxSize) 
      );
      
      filteredProperties = filteredProperties.filter(
        (propertyItem) => propertyItem.propertysizetype === sizeType
      );
    }
    


    setFilteredProperties(filteredProperties);
    setFiltersApplied(true);
    
  };



  // if (property.length === 0) {
  //   return (
  //     <div id="spinner" style={{backdropFilter: "blur(4px)"}} class="show position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
  //               <div class="spinner-border text-primary" style={{ width: "3rem", height: "3rem" }} role="status">
  //                   <span class="sr-only">Loading...</span>
  //               </div>
  //           </div>
  //   );
  // }

  return (
    <div>
    <div className="container-xxl bg-white p-0 " style={{ marginTop: 70 }}>
      <Search />
      <div className="main">
        <div
          className="filters"
        >
          <div>
            <p
              style={{
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              Filters
            </p>
            <div
              className="col-lg-12 col-md-3 col-sm-5"
              style={{ padding: "10px" }}
            >
              <select
                className="form-select border-0 py-3 filter-input"
                value={selectedPropertyType}
                onChange={(e) => { 
                  setSelectedPropertyType(e.target.value);
                  setSelectedPropertyTypeData(allpropertytypes.find(
                    (type) => type.propertytype === e.target.value
                  ));
                }
                }
              >
                <option value="">Property Type</option>
                {allpropertytypes.map((type) => (
                  <option key={type.propertytype} value={type.propertytype}>
                    {type.propertytype}
                  </option>
                ))}
              </select>
            </div>
            <div
              className="col-lg-12 col-md-3 col-sm-5"
              style={{ padding: "10px" }}
            >
              <select
                className="form-select border-0 py-3 filter-input"
                value={selectedPropertySubType}
                onChange={(e) => setSelectedPropertySubType(e.target.value)}
              >
                <option value="">Property SubType</option>
                {selectedPropertyTypeData?.propertysubtype?.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>

            {/* <div
              className="col-lg-12 col-md-3 col-sm-6"
              style={{ padding: "10px" }}
            >
              <select
                className="form-select border-0 py-3 filter-input"
                value={selectedCity}
                onChange={(e) => {
                  setSelectedCity(e.target.value);
                  setSelectedCityData(cityData.find(
                    (city) => city.city === e.target.value
                  ));
                }
                }
              >
                <option value="">City</option>
                {cityData.map((city) => (
                  <option key={city.city} value={city.city}>
                    {city.city}
                  </option>
                ))}
              </select>
            </div>
            <div
              className="col-lg-12 col-md-3 col-sm-6"
              style={{ padding: "10px" }}
            >
              <select
                className="form-select border-0 py-3 filter-input"
                value={selectedCityArea}
                onChange={(e) => setSelectedCityArea(e.target.value)}
              // disabled={!selectedCity || !selectedCity.cityareas}
              >
                <option value="">City Area</option>
                {selectedCityData &&
                  selectedCityData?.cityareas?.map((area) => (
                    <option key={area.areaText} value={area.areaText}>
                      {area.areaText}
                    </option>
                  ))}
              </select>
            </div> */}
            <p style={{textAlign:'center', paddingTop:"20px"}}>Enter Both Prices for accurate results</p>

            <div
            
              className="col-lg-12 col-md-3 col-sm-6"
              style={{ padding: "10px", display: "flex" }}
            >
              <input
                type="number"
                className="form-control border-0 py-3 filter-input"
                placeholder="Min Price"
                value={minPrice}
                onChange={(e) => setMinPrice(e.target.value)}
              />
              <p style={{ margin: "20px" }}>To</p>
              <input
                type="number"
                className="form-control border-0 py-3 filter-input"
                placeholder="Max Price"
                value={maxPrice}
                onChange={(e) => setMaxPrice(e.target.value)}
              />
            </div>
            <p style={{textAlign:'center', paddingTop:"20px"}}>Enter both sizes and type for accurate results</p>

            <div
              className="col-lg-12 col-md-3 col-sm-6"
              style={{ padding: "10px", display: "flex" }}
            >
              <input
                type="text"
                className="form-control border-0 py-3 filter-input"
                placeholder="Min Size"
                required
                value={minSize}
                onChange={(e) => setMinSize(e.target.value)}
              />
              <p style={{ margin: "20px" }}>To</p>
              <input
                type="text"
                className="form-control border-0 py-3 filter-input"
                placeholder="Max Size"
                required
                value={maxSize}
                onChange={(e) => setMaxSize(e.target.value)}
              />
            </div>
            <div
              className="col-lg-12 col-md-3 col-sm-6"
              style={{ padding: "10px" }}
            >
              <select
                className="form-select border-0 py-3 filter-input"
                value={sizeType}
                onChange={(e) => setSizeType(e.target.value)}
              >
                <option value="">Size Type</option>
                <option value="Marla">Marla</option>
                <option value="Feet">Feet</option>
                <option value="Yard">Yard</option>
                <option value="Meter">Meter</option>
                <option value="Kanal">Kanal</option>
              </select>
            </div>
            <div className="col-lg-12 col-md-3 col-sm-6">
              <button
                onClick={() =>
                  {
                    applyFilters({
                    searchTerm,
                    propertyType: selectedPropertyType,
                    propertySubType: selectedPropertySubType,
                    propertyPurpose: selectedPropertyPurpose,
                    city: selectedCity,
                    cityArea: selectedCityArea,
                    minPrice,
                    maxPrice,
                    minSize,
                    maxSize,
                    sizeType,
                  });
                  
                }
                }
                className="btn btn-primary w-100"
                style={{ padding: "16px", backgroundColor: "#00B98E" }}
              >
                Apply Filters
              </button>
            </div>
          </div>
        </div>
        <div
          className="listing"
        >
         
          <Listing
            property={filtersApplied ? filteredProperties : property}
            pagesize={10}
          />
        </div>
      </div>
      <Footer />
      <a
        href="/buyaproperty"
        className="btn btn-lg btn-primary btn-lg-square back-to-top"
      >
        <i className="bi bi-arrow-up"></i>
        <FaArrowUp />
      </a>
    </div>
  </div>
  );
}

export default CityProperties;
