import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Drawer, Menu, Layout, Button, Avatar } from "antd";
import {
  HomeOutlined,
  UserOutlined,
  PlusOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { AuthContext } from "./context/auth-context";
import { Formik } from "formik";
import axios from "axios";
const { Header, Footer } = Layout;
const ColorList = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"];
const GapList = [4, 3, 2, 1];
const App = (props) => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const regex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const phoneNoRegex = /^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/gm;
  const [profileData, setProfileData] = useState({});
  const [isMobile, setIsMobile] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMenuClicked, setIsMenuClicked] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [signup, setSignup] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [newPasswordModal, setNewPasswordModal] = useState(false);
  const [color, setColor] = useState(ColorList[0]);
  const [gap, setGap] = useState(GapList[0]);
  const [avatarInitial, setAvatarInitial] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/auth/user/${auth.userId}`
        );
        setProfileData(data);

        setAvatarInitial(data.name.charAt(0));
      } catch (error) {}
    };

    fetchData();
  }, []);

  const handleLoginModalClose = () => {
    setLoginModal(false);
    // navigate('/');
  };
  const handleOTPModalClose = () => {
    setOtpModal(false);
    navigate("/");
  };
  const handleNewPasswordModalClose = () => {
    setNewPasswordModal(false);
    navigate("/");
  };
  const handleSignupModalClose = () => {
    setSignup(false);
  };
  const showModal = () => {
    setLoginModal(true);
  };
  const showSignupModal = () => {
    setSignup(true);
  };

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };
  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Set initial screen size

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isMenuClicked) {
      setIsMenuClicked(false);
    }
  }, [isMenuClicked]);

  const handleLogout = async () => {
    Modal.confirm({
      title: "Are you sure, you want to Logout ?",
      cancelText: "No",
      okText: "Yes",
      okType: "primary",
      onOk: async () => {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_BASE_URL}/auth/logout`,
          withCredentials: true,
        });
        if (response.status === 200) {
          auth.logout();
          navigate("/");
        }
      },
    });
  };

  return (
    <div>
      <Layout className="layout" style={{ backgroundColor: "white" }}>
        {isMobile ? (
          <>
            <Header
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "white",
                marginTop: "20px",
                marginBottom: "20px",
                position: "fixed",
                width: "100%",
                zIndex: 100,
              }}
            >
              <Link to="/">
                <img
                  // className="img-fluid"
                  src={process.env.PUBLIC_URL + "/img/default.png"}
                  alt="Icon"
                  style={{ width: "200px" }}
                />
              </Link>

              <Button
                type="text"
                onClick={openDrawer}
                icon={<MenuOutlined style={{ fontSize: "20px" }} />}
              />
            </Header>
            <Drawer
              placement="left"
              closable={false}
              onClose={closeDrawer}
              open={isDrawerOpen}
            >
              <Menu
                theme="light"
                mode="inline"
                defaultSelectedKeys={["home"]}
                onClick={closeDrawer}
                style={{ fontSize: "20px" }}
              >
                <Menu.Item key="home" icon={<HomeOutlined />}>
                  <Link to="/">Home</Link>
                </Menu.Item>
                <Menu.Item key="about">
                  <Link to="/about">About</Link>
                </Menu.Item>
                <Menu.SubMenu
                  key="properties"
                  title="Properties"
                  icon={<UserOutlined />}
                >
                  <Menu.Item key="buyaproperty">
                    <Link to="/buyaproperty">Buy</Link>
                  </Menu.Item>
                  <Menu.Item key="sellproperty">
                    <Link to="/addproperty">Sell</Link>
                  </Menu.Item>
                  <Menu.Item key="rentaproperty">
                    <Link to="/rentaproperty">Rent</Link>
                  </Menu.Item>
                </Menu.SubMenu>
                <Menu.Item key="contact">
                  <Link to="/contact">Contact</Link>
                </Menu.Item>
                {auth.isLoggedIn === false ? (
                  <Menu.Item key="login" onClick={showModal}>
                    <Link>Login</Link>
                  </Menu.Item>
                ) : (
                  <Menu.SubMenu
                    key="myprofile"
                    title={
                      <div style={{ display: "flex" }}>
                        <Avatar
                          style={{
                            backgroundColor: color,
                            verticalAlign: "middle",
                          }}
                          size="large"
                          gap={gap}
                        >
                          {auth.username}
                        </Avatar>
                      </div>
                    }
                  >
                    <Menu.Item key="settings">
                      <Link to={`/profile/${auth.userId}`}>Profile</Link>
                    </Menu.Item>

                    <Menu.Item
                      key="addproperty"
                      onClick={() => {
                        // auth.logout();
                        handleLogout();
                        // navigate("/");
                      }}
                    >
                      <Link>Logout</Link>
                    </Menu.Item>
                  </Menu.SubMenu>
                )}
                {auth.isLoggedIn === false ? (
                  <Menu.Item key="signup" onClick={showSignupModal}>
                    Signup
                  </Menu.Item>
                ) : (
                  <Menu.Item
                    style={{ height: "0px", padding: "0px" }}
                  ></Menu.Item>
                )}
                <Menu.Item key="addProperty" icon={<PlusOutlined />}>
                  <Link to="/addproperty">Add Property</Link>
                </Menu.Item>
              </Menu>
            </Drawer>
          </>
        ) : (
          <Header
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "white",
              marginTop: "20px",
              marginBottom: "20px",
              position: "fixed",
              width: "100%",
              zIndex: 100, // Set a higher z-index value
              // justifyContent: "space-between", // Added property
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <a
                href="/"
                className="navbar-brand d-flex align-items-center text-center"
                style={{ width: "50%" }}
              >
                <img
                  className="img-fluid"
                  src={process.env.PUBLIC_URL + "/img/default.png"}
                  alt="Icon"
                  style={{ width: "200px" }}
                />

                {/* <h1 className="m-0 text-primary">MeraPakGhar</h1> */}
              </a>
              <Menu
                theme="light"
                mode="horizontal"
                defaultSelectedKeys={["home"]}
                style={{
                  width: "100%",
                  fontSize: "20px",
                  paddingLeft: "100px",
                }}
              >
                <Menu.Item key="home" icon={<HomeOutlined />}>
                  <Link to="/">Home</Link>
                </Menu.Item>
                <Menu.Item key="about">
                  <Link to="/about">About</Link>
                </Menu.Item>
                <Menu.SubMenu
                  key="properties"
                  title="Properties"
                  icon={<UserOutlined />}
                  style={{ fontSize: "20px" }}
                >
                  <Menu.Item key="buyaproperty">
                    <Link to="/buyaproperty">Buy</Link>
                  </Menu.Item>
                  <Menu.Item key="addproperty">
                    <Link to="/addproperty">Sell</Link>
                  </Menu.Item>
                  <Menu.Item key="rentaproperty">
                    <Link to="/rentaproperty">Rent</Link>
                  </Menu.Item>
                </Menu.SubMenu>
                <Menu.Item key="contact">
                  <Link to="/contact">Contact</Link>
                </Menu.Item>

                {auth.isLoggedIn === false ? (
                  <Menu.Item key="login" style={{ marginLeft: "100px" }}>
                    <Link onClick={showModal}>Login</Link>
                  </Menu.Item>
                ) : (
                  <Menu.SubMenu
                    key="myprofile"
                    title={
                      <div style={{ display: "flex" }}>
                        <Avatar
                          style={{
                            backgroundColor: color,
                            verticalAlign: "middle",
                            marginTop: "15px",
                            //   padding:'30px',
                          }}
                          size="large"
                          gap={gap}
                        >
                          {auth.username}
                        </Avatar>
                      </div>
                    }
                    style={{ marginLeft: "150px" }}
                  >
                    <Menu.Item key="settings">
                      <Link to={`/profile/${auth.userId}`}>Profile</Link>
                    </Menu.Item>

                    <Menu.Item
                      key="addproperty"
                      onClick={() => {
                        handleLogout();
                        // auth.logout();
                        // navigate("/");
                      }}
                    >
                      <Link>Logout</Link>
                    </Menu.Item>
                  </Menu.SubMenu>
                )}
                {auth.isLoggedIn === false ? (
                  <Menu.Item key="signup" onClick={showSignupModal}>
                    <Link>Signup</Link>
                  </Menu.Item>
                ) : (
                  <Menu.Item style={{ padding: "0px" }}></Menu.Item>
                )}
                <Menu.Item key="addProperty" icon={<PlusOutlined />}>
                  <Link to="/addproperty">Add Property</Link>
                </Menu.Item>
              </Menu>
            </div>
          </Header>
        )}

        <Footer>
          <div>
            <Modal
              style={{ fontSize: "24px" }}
              title={
                <h2 style={{ fontSize: "24px", textAlign: "center" }}>Login</h2>
              }
              open={loginModal}
              onOk={handleLoginModalClose}
              onCancel={handleLoginModalClose}
              width={450}
              footer={null}
              bodyStyle={{
                height: "500px",
                marginTop: "50px",
                marginLeft: "0px",
                paddingLeft: "0px",
              }}
            >
              <Formik
                initialValues={{ email: "", password: "" }}
                validate={(values) => {
                  const errors = {};
                  if (!values.email) {
                    errors.email = "Required";
                  } else if (!regex.test(values.email)) {
                    errors.email = "Email is Not valid";
                  }
                  if (!values.password) {
                    errors.password = "Required";
                  } else if (values.password.length < 8) {
                    errors.password = "Password must be of 8 characters";
                  }
                  return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    const response = await axios({
                      method: "post",
                      // url: `http://localhost:5000/auth/login`,
                      url: `${process.env.REACT_APP_BASE_URL}/auth/login`,
                      data: {
                        email: values.email,
                        password: values.password,
                      },
                      withCredentials: true,
                    });

                    setProfileData(response.data.user);
                    if (response.data.message) {
                      Modal.warning({ title: response.data.message });
                    } else {
                      auth.login(
                        response.data.isLoggedIn,
                        response.data.user._id,
                        response.data.user.name
                      );
                      if (response.status === 200) {
                        Modal.success({
                          title: "User Login Successfully!",
                        });

                        handleLoginModalClose();
                      }
                    }
                  } catch (err) {
                    // console.log(err);
                    // const messsage = err.response.data.message;
                    // Error(messsage || "Something went wrong,Please try again!");
                    // alert(messsage || "Something went wrong,Please try again!");
                  }
                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    id="editmodal"
                    className="w-full max-w-sm"
                  >
                    <div
                      className="md:flex md:items-center mb-6"
                      style={{ paddingTop: "40px" }}
                    >
                      <div class="col-12" style={{ marginBottom: "20px" }}>
                        <div class="form-floating">
                          <input
                            type="email"
                            name="email"
                            // id="phone_no"
                            class="form-control"
                            placeholder="Your Phone No"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                          <p style={{ color: "red" }}>
                            {errors.email && touched.email && errors.email}
                          </p>
                          <label for="phone_no">Your Email</label>
                        </div>
                      </div>
                      <div
                        class="col-12"
                        style={{
                          paddingTop: "20px",
                          paddingBottom: "20px",
                        }}
                      >
                        <div class="form-floating">
                          <input
                            type="password"
                            name="password"
                            class="form-control"
                            id="password"
                            placeholder="Your Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                          />
                          <p style={{ color: "red" }}>
                            {errors.password &&
                              touched.password &&
                              errors.password}
                          </p>
                          <label for="password">Your Password</label>
                        </div>
                      </div>
                      <div
                        class="col-12"
                        style={{ paddingTop: "60px", textAlign: "center" }}
                      >
                        <p style={{ textalign: "center", fontSize: "20px" }}>
                          Don't have an account?
                          <Link
                            style={{ color: "blue" }}
                            onClick={() => {
                              handleLoginModalClose();
                              setSignup(true);
                            }}
                          >
                            {" "}
                            SignUp
                          </Link>
                          <Link
                            style={{ marginLeft: "70px", textAlign: "center" }}
                            onClick={() => {
                              setOtpModal(true);
                              handleLoginModalClose();
                            }}
                          >
                            {" "}
                            Forget Password
                          </Link>
                        </p>
                      </div>
                      <div
                        class="col-12"
                        style={{
                          paddingTop: "20px",
                          paddingBottom: "30px",
                        }}
                      >
                        <button
                          class="btn btn-primary w-100 py-3"
                          type="submit"
                          disabled={isSubmitting}
                          // onClick={handleClick}
                        >
                          Login
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </Modal>
            <Modal
              style={{ fontSize: "24px", textAlign: "center" }}
              open={otpModal}
              onCancel={handleOTPModalClose}
              maskClosable={false}
              width={450}
              footer={null}
              bodyStyle={{
                marginTop: "50px",
                marginLeft: "0px",
                paddingLeft: "0px",
                textAlign: "center",
              }}
            >
              <Formik
                initialValues={{ email: "", otp: "" }}
                validate={(values) => {
                  const errors = {};
                  // Validation logic
                  return errors;
                }}
                onSubmit={async (values, actions) => {
                  try {
                    const response = await axios({
                      method: "post",
                      url: `${process.env.REACT_APP_BASE_URL}/auth/send-otp`,
                      data: {
                        email: values.email,
                      },
                      withCredentials: true,
                    });

                    if (response.status === 200) {
                      handleOTPModalClose(); // Close the OTP modal
                      setNewPasswordModal(true);
                    }
                  } catch (err) {
                    alert(err.response.data.message);
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    id="editmodal"
                    className="w-full max-w-sm"
                  >
                    <div
                      className="md:flex md:items-center mb-6"
                      style={{ paddingTop: "40px" }}
                    >
                      <div
                        className="col-12"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div className="form-floating" style={{ width: "70%" }}>
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            id="email"
                            placeholder="Your Email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                          <p style={{ color: "red" }}>
                            {errors.email && touched.email && errors.email}
                          </p>
                          <label htmlFor="password">Email</label>
                        </div>
                        <div style={{ width: "30%", margin: "0px" }}>
                          <button
                            className="btn btn-primary w-full py-2"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            Send OTP
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </Modal>
            <Modal
              style={{ fontSize: "24px", textAlign: "center" }}
              open={newPasswordModal}
              onCancel={handleNewPasswordModalClose}
              maskClosable={false}
              width={450}
              footer={null}
              bodyStyle={{
                height: "500px",
                marginTop: "50px",
                overflowY: "scroll",
                marginLeft: "0px",
                paddingLeft: "0px",
                textAlign: "center",
              }}
            >
              <Formik
                initialValues={{
                  otp: "",
                  password: "",
                  confirmpassword: "",
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.password) {
                    errors.password = "Password Required";
                  } else if (values.password.length < 8) {
                    errors.password = "Password must be of 8 characters";
                  }
                  if (!values.confirmpassword) {
                    errors.confirmpassword = "Confirm Password Required";
                  } else if (values.password !== values.confirmpassword) {
                    errors.confirmpassword = "Incorrect Confirm Password";
                  }
                  return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    const response = await axios({
                      method: "post",
                      url: `${process.env.REACT_APP_BASE_URL}/auth/submit-otp`,
                      data: {
                        otp: values.otp,
                        password: values.password,
                      },
                      // withCredentials: true,
                    });
                    if (response.status === 200) {
                      alert("Password Updated.");
                      setLoginModal(true);
                      handleNewPasswordModalClose();
                    } else {
                      alert("server err / wrong OTP");
                    }
                  } catch (err) {
                    const message = err.response.data.message;
                    alert(message);
                  }
                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    id="editmodal"
                    className="w-full max-w-sm"
                  >
                    <div
                      className="md:flex md:items-center mb-6"
                      style={{ paddingTop: "40px" }}
                    >
                      <div
                        className="col-12"
                        style={{
                          paddingTop: "20px",
                          paddingBottom: "20px",
                        }}
                      >
                        <div className="form-floating">
                          <input
                            type="otp"
                            name="otp"
                            className="form-control"
                            id="otp"
                            placeholder="Your Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.otpModal}
                          />

                          <label htmlFor="password">OTP</label>
                        </div>
                      </div>
                      <div
                        className="col-12"
                        style={{
                          paddingTop: "10px",
                          paddingBottom: "20px",
                        }}
                      >
                        <div className="form-floating">
                          <input
                            type="password"
                            name="password"
                            className="form-control"
                            id="password"
                            placeholder="Your Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                          />
                          <p style={{ color: "red" }}>
                            {errors.password &&
                              touched.password &&
                              errors.password}
                          </p>
                          <label htmlFor="password">New Password</label>
                        </div>
                      </div>
                      <div
                        className="col-12"
                        style={{
                          paddingTop: "10px",
                          paddingBottom: "20px",
                        }}
                      >
                        <div className="form-floating">
                          <input
                            type="password"
                            name="confirmpassword"
                            className="form-control"
                            id="confirmpassword"
                            placeholder="Confirm Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmpassword}
                          />
                          <p style={{ color: "red" }}>
                            {errors.confirmpassword &&
                              touched.confirmpassword &&
                              errors.confirmpassword}
                          </p>
                          <label htmlFor="password">Confirm Password</label>
                        </div>
                      </div>

                      <div
                        className="col-12"
                        style={{
                          paddingTop: "20px",
                          paddingBottom: "30px",
                        }}
                      >
                        <button
                          className="btn btn-primary w-100 py-3"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </Modal>
          </div>
          <div>
            <Modal
              open={signup}
              onCancel={handleSignupModalClose}
              keyboard={false}
              title={
                <h2 style={{ fontSize: "24px", textAlign: "center" }}>
                  Signup
                </h2>
              }
              footer={null}
              width={450}
              bodyStyle={{
                marginTop: "50px",
              }}
            >
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  phoneno: "",
                  password: "",
                  confirmpassword: "",
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.name) {
                    errors.name = "Required";
                  } else if (values.name.length < 3) {
                    errors.name = "Name should be at least 3 characters";
                  }
                  if (!values.email) {
                    errors.email = "Required";
                  } else if (!regex.test(values.email)) {
                    errors.email = "Email is not valid";
                  }

                  if (!values.phoneno) {
                    errors.phoneno = "Required";
                  } else if (!phoneNoRegex.test(values.phoneno)) {
                    errors.phoneno = "WhatsApp No. is not valid";
                  }

                  if (!values.password) {
                    errors.password = "Required";
                  } else if (values.password.length < 8) {
                    errors.password =
                      "Password should be at least 8 characters";
                  }

                  if (!values.confirmpassword) {
                    errors.confirmpassword = "Required";
                  } else if (values.confirmpassword !== values.password) {
                    errors.confirmpassword = "Passwords do not match";
                  }

                  return errors;
                }}
                onSubmit={async (values, actions) => {
                  try {
                    const response = await axios({
                      method: "post",
                      url: `${process.env.REACT_APP_BASE_URL}/auth/signup`,
                      data: {
                        name: values.name,
                        email: values.email,
                        phoneno: values.phoneno,
                        password: values.password,
                      },
                      withCredentials: true,
                    });
                    if (response.status === 200) {
                      // const response1 = await axios({
                      //   method: "post",
                      //   // url: `http://localhost:5000/auth/login`,
                      //   url: `${process.env.REACT_APP_BASE_URL}/auth/login`,
                      //   data: {
                      //     email: values.email,
                      //     password: values.password,
                      //   },
                      //   withCredentials: true,
                      // });
                      // console.log(response1);
                      // setProfileData(response1.data.user);
                      // auth.login(
                      //   response1.data.isLoggedIn,
                      //   response1.data.user._id,
                      //   response1.data.user.name
                      // );

                      Modal.success({
                        title:
                          "User Added Successfully and a verification mail is sent to your email please verify!",
                      });
                      handleSignupModalClose();
                      setLoginModal(true);
                    }
                  } catch (err) {
                    // const messsage = err.response.data.message;
                    // Error(messsage || "Something went wrong,Please try again!");
                    // alert(err.response.data.message);
                  }
                }}
                // setTimeout(() => {
                //   alert(JSON.stringify(values, null, 2));
                //   setSubmitting(false);
                // }, 400);
                // }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    id="editmodal"
                    className="w-full max-w-sm"
                  >
                    <div className="md:flex md:items-center mb-6">
                      <div class="col-12">
                        <div class="form-floating">
                          <input
                            type="text"
                            name="name"
                            class="form-control"
                            id="name"
                            placeholder="Your Name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                          />
                          <p style={{ color: "red" }}>
                            {errors.name && touched.name && errors.name}
                          </p>
                          {/* {errors.name && touched.name && errors.name} */}
                          <label for="name">Name</label>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-floating">
                          <input
                            type="email"
                            class="form-control"
                            id="email"
                            name="email"
                            placeholder="Your Email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                          <p style={{ color: "red" }}>
                            {errors.email && touched.email && errors.email}
                          </p>
                          <label for="phone">Email</label>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-floating">
                          <input
                            type="text"
                            class="form-control"
                            id="phoneno"
                            name="phoneno"
                            placeholder="WhatsApp No."
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phoneno}
                          />
                          <p style={{ color: "red" }}>
                            {errors.phoneno &&
                              touched.phoneno &&
                              errors.phoneno}
                          </p>
                          <label for="password">WhatsApp</label>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-floating">
                          <input
                            type="password"
                            class="form-control"
                            id="password"
                            name="password"
                            placeholder="Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                          />
                          <p style={{ color: "red" }}>
                            {errors.password &&
                              touched.password &&
                              errors.password}
                          </p>
                          <label for="password">Password</label>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="form-floating">
                          <input
                            type="password"
                            class="form-control"
                            id="confirmpassword"
                            placeholder="Confirm Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmpassword}
                          />
                          <p style={{ color: "red" }}>
                            {errors.confirmpassword &&
                              touched.confirmpassword &&
                              errors.confirmpassword}
                          </p>
                          <label for="confirmpassword">Confirm Password</label>
                        </div>
                      </div>

                      <div class="col-12">
                        <p style={{ textalign: "center", fontSize: "20px" }}>
                          {" "}
                          Have an account?{" "}
                          <Link
                            onClick={() => {
                              handleSignupModalClose();
                              setLoginModal(true);
                            }}
                          >
                            {" "}
                            LogIn
                          </Link>
                        </p>
                      </div>
                      <div class="col-12" style={{ paddingBottom: "30px" }}>
                        <button
                          class="btn btn-primary w-100 py-3"
                          type="submit"
                        >
                          SignUp
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </Modal>
          </div>
        </Footer>
      </Layout>
    </div>
  );
};

export default App;
