import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../css/bootstrap.min.css";
import "../css/style.css";
import { Modal } from "antd";
import { Link } from "react-router-dom";
import { Formik } from "formik";


function Signup() {
  const [signupModal, setSignupModal] = useState(true);
  const handleSignupModalClose = () => {
    setSignupModal(false);
  };
  
 

  const Error = "";
  const regex = /^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/gm;
  return (
    <div /*class="container-xxl bg-white p-0"*/>
      <Modal
        style={{ marginTop: "20px", textAlign: "center" }}
        open={signupModal}
        onCancel={handleSignupModalClose}
        keyboard={false}
        title={<h2>Signup</h2>}
        footer={null}
        width={450}
        bodyStyle={{
          marginTop: "50px",
        }}
      >
        <Formik
          initialValues={{
            name: "",
            email: "",
            password: "",
            confirmpassword: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "Required";
            }

            if (!values.email) {
              errors.email = "Required";
            } 

            if (!values.password) {
              errors.password = "Required";
            }

            if (!values.confirmpassword) {
              errors.confirmpassword = "Required";
            } else if (!(values.confirmpassword === values.password)) {
              errors.confirmpassword = "invalid Password";
            }

            return errors;
          }}
          onSubmit={async (values, actions) => {
            try {
              const response = await axios({
                method: "post",
                url: `${process.env.REACT_APP_BASE_URL}/auth/signup`,
                data: {
                  name: values.name,
                  email: values.email,
                  password: values.password,
                },
              });

              if (response.status === 200) {
                alert("User Added Successfully!", response.data.message);
                Modal.success({
                  title: "User Added Successfully and a verification mail is sent to your email please verify!",
                });
                handleSignupModalClose();
              }
            } catch (err) {
              const messsage = err.response.data.message;
              Error(messsage || "Something went wrong,Please try again!");
              alert(err.response.data.message, err.response.status);
            }
          }}
          // setTimeout(() => {
          //   alert(JSON.stringify(values, null, 2));
          //   setSubmitting(false);
          // }, 400);
          // }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form
              onClick={handleSubmit}
              id="editmodal"
              className="w-full max-w-sm"
            >
              <div className="md:flex md:items-center mb-6">
                <div class="col-12">
                  <div class="form-floating">
                    <input
                      type="text"
                      name="name"
                      class="form-control"
                      id="name"
                      placeholder="Your Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    <p style={{ color: "red" }}>
                      {errors.name && touched.name && errors.name}
                    </p>
                    {/* {errors.name && touched.name && errors.name} */}
                    <label for="name">Name</label>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-floating">
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      name="email"
                      placeholder="Your Email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <p style={{ color: "red" }}>
                      {errors.email && touched.email && errors.email}
                    </p>
                    <label for="phone">Email</label>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-floating">
                    <input
                      type="password"
                      class="form-control"
                      id="password"
                      name="password"
                      placeholder="Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    <p style={{ color: "red" }}>
                      {errors.password && touched.password && errors.password}
                    </p>
                    <label for="password">Password</label>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-floating">
                    <input
                      type="password"
                      class="form-control"
                      id="confirmpassword"
                      placeholder="Confirm Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirmpassword}
                    />
                    <p style={{ color: "red" }}>
                      {errors.confirmpassword &&
                        touched.confirmpassword &&
                        errors.confirmpassword}
                    </p>
                    <label for="confirmpassword">Confirm Password</label>
                  </div>
                </div>

                <div class="col-12">
                  <p>
                    {" "}
                    Have an account? <Link to="/login"> LogIn</Link>
                    
                     
                  </p>
                </div>
                <div class="col-12" style={{ paddingBottom: "30px" }}>
                  <button class="btn btn-primary w-100 py-3" type="submit">
                    SignUp
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
     
    </div>
  );
}

export default Signup;
