import React, { useContext } from "react";
import { LikeOutlined, HomeOutlined, StarOutlined } from "@ant-design/icons";
import { Avatar, List, Space } from "antd";
import { FaMap } from "react-icons/fa";
import ProfileDemo from "../component/profileDemo";
import Carousel from "./ImageCarousel";
import "./css/listing.css";
import { Link } from "react-router-dom";

function Home(props) {
  const data = Array.isArray(props.property) && props.property.length > 0
    ? props.property.map((value, i) => ({
      userId: value.userId,
      href: `${`/viewproperty/${value.id}`}`,
      title: value.propertytitle,
      avatar: <ProfileDemo userId={value.userId} />,
      description: value.propertydescription,
      propertyImage: value.images,
      price: value.propertyprice,
      city: value.propertycity,
      subtype: value.propertysubtype,
      likecount: value.likecount,
    }))
    : [];
  
  const IconText = ({ icon, text }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  );

  return (
    <div className="homepage">
      <div className="listData">
        <List
          className="list"
          itemLayout="vertical"
          size="large"
          pagination={{
            onChange: (page) => {
              console.log(page);
            },
            pageSize: 5,
          }}
          dataSource={data}
          renderItem={(item) => (
            console.log(item),
            (
              <List.Item
                className="property-list"
                key={item.title}
                actions={[
                  <IconText
                    icon={LikeOutlined}
                    text={item.likecount}
                    key="list-vertical-like-o"
                  />,
                  <IconText
                    icon={FaMap}
                    text={item.city}
                    key="list-vertical-like-o"
                  />,
                  <IconText
                    icon={HomeOutlined}
                    text={item.subtype}
                    key="list-vertical-like-o"
                  />,
                ]}
                extra={
                  item.propertyImage?.length > 0 ? (
                    <img
                      style={{ width: "300px", height: "200px" }}
                      id="login_img"
                      // src={process.env.REACT_APP_BASE_TWO_URL+'/'+item.propertyImage[0]}
                      src={`https://nodeapp.merapakghar.com/${item.propertyImage[0]}`}
                      alt="Image not Displaying"
                    />
                  ) : (
                    <img
                      style={{ width: "300px", height: "200px" }}
                      id="login_img"
                      src={"./img/imgbackground.jpg"}
                      alt=""
                    />
                  )
                }
              >
                <List.Item.Meta
                  // avatar={item.avatar} //user image
                  title={
                    <a
                      style={{
                        color: "#2e307d",
                        fontSize: "22px",
                        fontWeight: "bold",
                        fontFamily: "tahoma",
                      }}
                      href={item.href}
                    >
                      {item.title}
                    </a>
                  }
                  description={
                    <p className="description">{item.description}</p>
                  }
                />
                {item.content}
                <div>
                  <p
                    style={{
                      color: "#2e307d",
                      fontSize: "22px",
                      fontWeight: "bold",
                    }}
                  >
                    Rs. {item.price}
                  </p>
                  <div style={{ display: "flex" }}>
                    <Link to={item.href}>
                      <button className="btn view-detail">View Detail</button>
                    </Link>
                  </div>
                </div>
              </List.Item>
            )
          )}
        />
      </div>
    </div>
  );
}

export default Home;
